
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


export default function NavbarContainer({ title = "", children }) {

    const navigate = useNavigate()

    return (
        <div className="flex flex-col bg-gradient-to-t from-[#ccd4f8] via-[#f4ebfb] to-white h-screen">
            <div className="navbar fixed top-0 w-full z-[999] bg-white">
                <div className="flex-none">
                    <button className="btn btn-ghost" onClick={() => navigate(-1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="arcs"><path d="M15 18l-6-6 6-6"></path></svg>
                    </button>
                </div>
                <div className="navbar-start">
                    <a className="btn btn-ghost normal-case text-xl">{title}</a>
                </div>
            </div>
            <div className="h-full overflow-y-auto overflow-x-hidden">
                <div className="pb-32 pt-[56px] ">
                    {children}
                </div>
            </div>
        </div>
    )
}