import React, {useState, useEffect, useRef} from "react";
import { updateInfoAPI } from './utils/api.ts';
import { useUser } from "./context/UserContext.tsx";
import { Link } from "react-router-dom";

export default function SettingPage() {

    const {user, setUser} = useUser()

    const [nickname, setNickname] = useState("")
    const [loading, setLoading] = useState(false)

    const updateNicknameDialogRef = useRef<HTMLDialogElement | null>(null);

    useEffect(()=>{
        setNickname(user?.nickname || "")
    }, [user?.nickname])

    const handleSave = async () => {
        if (loading) {
            return
        }
        setLoading(true)
        const res = await updateInfoAPI(nickname)
        if (res !== null) {
            setUser(res)
        }
        setLoading(false)
    }

    function showImageGenerateDialog() {
        if (updateNicknameDialogRef && updateNicknameDialogRef.current) updateNicknameDialogRef.current.showModal()
    }

    const navbar = () => (
        <div className="navbar fixed top-0 w-full z-[999] bg-white">
            <div className="flex-1"></div>
            <div className="navbar-end">
                <Link to="https://bwbbsvqza3t.feishu.cn/wiki/XqvawsQ0niZcC9kyUPccel35n7c" target="_blank" className="btn btn-ghost">
                    联系我们
                </Link>
            </div>
        </div>
    )

    const updateNicknameDialog = () => {
        return (
            <dialog ref={updateNicknameDialogRef} className="modal">
                <div className="modal-box space-y-4">
                    <h3 className="font-bold text-lg">修改昵称</h3>
                    <div className="flex flex-col space-y-2" >
                        <input type="text" value={nickname}
                            onChange={e=>setNickname(e.target.value)} className="input input-bordered w-full max-w-md" 
                        />
                    </div>
                    <div className="modal-action">
                        <form method="dialog" className="space-x-4">
                            <button className="btn" onClick={handleSave} >保存</button>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }

    return (
        <div>
            {navbar()}
            <div className="flex flex-col px-3">
                {updateNicknameDialog()}
                <div className="card card-side">
                    <div className="card-body">
                        <div className="flex flex-row space-x-2">
                            <h2 className="card-title text-xl">{nickname}</h2>
                            <button className="btn btn-sm rounded-full" onClick={showImageGenerateDialog}><i className="far fa-edit"></i></button>
                        </div>
                        <div className="text-sm"><b>用户名</b> {user?.username}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}