import React, { useEffect, useState } from "react";
import { Character } from '../utils/type.ts';
import LoadingPage from "../LoadingPage.tsx";
import NavbarContainer from "../NavbarContainer.tsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import Zoom from 'react-medium-image-zoom'
import { getUserAlbum } from "../utils/api.ts";
import { useUser } from "../context/UserContext.tsx";

interface CharacterProps {
    character: Character;
}

function CharacterInfo({character}: CharacterProps) {
    return (
        <div className="flex flex-col space-y-2 py-2">
            <div className="card bg-white p-4">
                <p><b>性别</b> {character.gender}</p>
            </div>
            <div className="card bg-white p-4">
                <p><b>介绍</b> {character.bio}</p>
            </div>
            <div className="card bg-white p-4">
                <p><b>外貌</b> {character.appearance}</p>
                <Zoom><img src={character.portrait_url} className="rounded w-48 py-2"/></Zoom>
            </div>
        </div>
    )
}

const PhotoGallery = ({character}: CharacterProps) => {

    const [photos, setPhotos] = useState([])
    useEffect(()=>{
       /* const getPhotosAsync = async () => {
            const photos = await getUserAlbum()
            setPhotos(photos)
        }
        getPhotosAsync()*/
    }, [character])

    /*
    return (
        <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
                {photos.map((photo, index) => (
                    <div className="flex justify-center items-center overflow-hidden w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 p-2">
                        <div className="rounded-lg bg-white">
                            <Zoom><img src={photo} className="object-contain aspect-square rounded-lg" /></Zoom>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
    */
    return <p className="p-8">😫 功能暂未开启</p>
};


export default function CharacterPage() {

    const tabs = [
        { name: '信息' },
        { name: '相册' }
    ];

    const {charID} = useParams()
    const {user} = useUser()
    const navigate = useNavigate()

    const characterLSID = `character-${charID}`
    const [character, setCharacter] = useState<Character | null>(() => {
        const localChar = localStorage.getItem(characterLSID);
        return localChar !== null ? JSON.parse(localChar) : null;
    });
    useEffect(() => {
        localStorage.setItem(characterLSID, JSON.stringify(character));
    }, [character]);
    
    const [activeTab, setActiveTab] = useState(tabs[0].name);    

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    if (character === null) {
        return <LoadingPage />
    }

    return (
        <NavbarContainer title="角色档案">
            <div className="flex flex-col px-3">
                <div className="card card-side px-3">
                    {character && <figure><img src={character?.avatar_url} alt="avatar" className="h-16 w-16 rounded-full" /></figure>}
                    <div className="card-body">
                        <h2 className="card-title">{character?.name}</h2>
                    </div>
                    <figure><button className="btn btn-outline" onClick={()=>navigate(`/character/create/${charID}`)} disabled={character.author_user_id !== user?.id}>编辑</button></figure>
                </div>
                <div role="tablist" className="tabs tabs-bordered">
                    {tabs.map((tab) => (
                        <a
                            key={tab.name}
                            role="tab"
                            className={`tab ${activeTab === tab.name ? 'tab-active' : ''}`}
                            onClick={() => handleTabClick(tab.name)}
                        >
                            {tab.name}
                        </a>
                    ))}
                </div>
                {activeTab === tabs[0].name && <CharacterInfo character={character}/>}
                {activeTab === tabs[1].name && <PhotoGallery character={character}/>}
            </div>
        </NavbarContainer>
    )
}