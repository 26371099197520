import './ChatPage.css';
import RatingEntry from './RatingEntry.js';
import useVoiceBot from './useVoiceBot.ts';
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast"
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { dateToText } from '../utils/date.js';
import LoadingPage from '../LoadingPage.tsx';
import 'react-medium-image-zoom/dist/styles.css'
import Markdown from '../common/markdown.tsx';
import { moodIcon } from '../utils/mood.ts';
import NewTopicButton from './NewTopicButton.tsx';

const model_list= {
    2: "🔬 Pro模型" ,
    3: "💬 闲聊模型" ,
    5: "🖊️ 故事模型" ,
}

function ChatPage() {

    const { charID } = useParams()

    const [searchParams, setSearchParams] = useSearchParams();
    const broadcastModalRef = useRef<HTMLDialogElement | null>(null);
    const modelModalRef = useRef<HTMLDialogElement | null>(null);

    const navigate = useNavigate()

    const {
        processing,
        character,
        history,
        userText,
        cookAIText,
        deleteEvent,
        regenEvent,
        voiceOn,
        setVoiceOn,
    } = useVoiceBot(charID || "")

    const historyEndRef = useRef<null | HTMLDivElement>(null)

    const [model, setModel] = useState(() => {
        const savedModel = localStorage.getItem('model');
        return savedModel !== null ? JSON.parse(savedModel) : 3;
    });
    
    useEffect(() => {
        localStorage.setItem('model', JSON.stringify(model));
    }, [model]);

    const scrollToBottom = () => {
        historyEndRef.current?.scrollIntoView()
    }

    const removeBroadcastParam = async () => {
        if (searchParams.has('broadcast')) {
            const topic_id = Number(searchParams.get('broadcast'))
            searchParams.delete('broadcast');
            setSearchParams(searchParams);
            if (topic_id && character) {
                await cookAIText(character.id, topic_id, true)
            }
        }
    }

    useEffect(() => {
        if (history?.length) {
            scrollToBottom()
        }
        removeBroadcastParam()
    }, [history, character]);
    
    const handleModelChange = (model) => {
        setModel(model)
        handleClose(modelModalRef)
    };

    const send = (input) => {
        userText(input, model)
    };

    if (character === null) {
        return <LoadingPage />
    }

    const back = () => {
        if (searchParams.has('back')) {
            const backURL = searchParams.get('back') || ""
            navigate(backURL)
            return
        }
        navigate(-1)
    }

    const navbar = () => {
        return (
        <div className="navbar fixed top-0 w-full z-[999] bg-white">
            <div className="navbar-start">
                <button className="btn btn-ghost" onClick={back}>
                    <i className="fa-solid fa-chevron-left"></i>
                </button>
            </div>
            <div className="navbar-center gap-1">
                <div className="text-xl">{character.name}</div>
                <div className="btn btn-xs" onClick={()=>handleOpen(modelModalRef)}>{model_list[model]}</div>
            </div>
            <div className="navbar-end space-x-4 px-2">
                <label className="swap">

                    {/* this hidden checkbox controls the state */}
                    <input type="checkbox" checked={voiceOn} onChange={() => setVoiceOn(!voiceOn)} />

                    {/* volume on icon */}
                    <i className="fa fa-volume-high swap-on"></i>

                    {/* volume off icon */}
                    <i className="fa fa-volume-xmark swap-off"></i>
                </label>
            </div>
        </div>
        )
    }

    const handleClose = (modalRef) => {
        if (modalRef.current) {
            modalRef.current.close()
        }
      }
    
    const handleOpen = (modalRef) => {
        if (modalRef.current) {
            modalRef.current.show()
        }
    }

    const createTopicDialog = () => {
        return (
            <dialog className="modal" ref={broadcastModalRef}>
                <div className="modal-box flex flex-col space-y-2">
                    <h3 className="font-bold text-lg">创建一个话题</h3>
                    <p>用右下角的 <i className="fa fa-pencil"></i> 按钮来为角色创建一个话题吧！</p>
                    <button onClick={()=>handleClose(broadcastModalRef)} className="btn btn-sm btn-outline btn-secondary gap-2">
                        知道了
                    </button>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={()=>handleClose(broadcastModalRef)}>close</button>
                </form>
            </dialog>
        )
    }


    const selectModelDialog = () => {
        return (
            <dialog className="modal" ref={modelModalRef}>
                <div className="modal-box flex flex-col space-y-4">
                    <h3 className="font-bold text-lg">选择一个AI模型</h3>
                    <button onClick={()=>handleModelChange(3)} className="btn btn-outline btn-secondary gap-2">
                    💬 闲聊模型：基础的闲聊模型。
                    </button>
                    <button onClick={()=>handleModelChange(5)} className="btn btn-outline btn-secondary gap-2">
                    🖊️ 故事模型：回复更长的模型。
                    </button>
                    <button onClick={()=>handleModelChange(2)} className="btn btn-outline btn-secondary gap-2">
                    🔬 Pro模型：最聪明的模型。
                    </button>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={()=>handleClose(modelModalRef)}>close</button>
                </form>
            </dialog>
        )
    }

    const bio = ()=> (
        <div className="p-4">
            <div className="card card-compact card-body bg-slate-200">
                <Markdown>{`**简介** ${character.bio}`}</Markdown>
            </div>
        </div>
    )

    return (
        <div className="flex flex-col bg-gradient-to-t from-[#ccd4f8] via-[#f4ebfb] to-white h-screen">
            {navbar()}
            <div className="chat-container flex flex-col h-full w-screen">
                <div className="w-screen md:w-2/5 h-full self-center overflow-y-auto overflow-x-hidden">
                    <div className="pt-[64px] pb-20">
                        {bio()}
                        {history && history.map((entry, index, arr) => (
                            <ChatEntry key={index}
                                entry={entry} prevEntry={arr[index - 1]}
                                character={character}
                                deleteEvent={async ()=>await deleteEvent(character.id, entry["id"])}
                                regenEvent={async ()=>await regenEvent(character.id, entry["id"], model)}
                                isLast={index == history.length-1}
                            />
                        ))}
                        {(processing && model!=2) ? <LoadingBubble character={character} /> : null}
                        <div ref={historyEndRef} />
                    </div>
                </div>
                <div className={`fixed bottom-0 w-full z-[999] bg-white flex-row flex w-full md:w-2/5 self-center justify-center items-center p-4 space-x-4`}>
                    <ChatInput send={send} processing={processing} />
                    <NewTopicButton character={character} cookAIText={cookAIText} processing={processing} />
                </div>
            </div>
            {createTopicDialog()}
            {selectModelDialog()}
        </div>
    );
}

function ChatInput(props) {
    const [input, setInput] = useState("");

    const textAreaRef = useRef<null | HTMLTextAreaElement>(null)

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (props.processing) {
            return
        }
        if (e.keyCode === 13 && !e.shiftKey && input.trim() !== '') {
            e.preventDefault()
            setInput("")
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            if (isMobile) {
                textAreaRef.current?.blur();
            }
            props.send(input)
        }
    };

    return (
        <textarea placeholder="发送消息" className="textarea textarea-bordered resize-none grow"
            rows={1}
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            ref={textAreaRef}
        />
    )
}

function Avatar(props) {
    return (
        <Link to={`/character/${props.character.id}`} >
            <div className="chat-image avatar">
                <div className="w-12 rounded-full">
                    <img src={props.character.avatar_url} />
                </div>
            </div>
        </Link>
    )
}

function LoadingBubble(props) {
    return (
        <div className={`chat chat-start px-4 py-2`}>
            <Avatar character={props.character} />
            <div className={`break-words max-w-full chat-bubble chat-bubble-primary`}>
                <span className="loading loading-dots loading-sm"></span>
            </div>
        </div>
    )
}

function GoToBroadcast({topic_id}) {
    return (
        <Link className={`btn btn-white btn-sm`} to={`/broadcasts/topics/${topic_id}`}>
            <i className={`fa fa-circle-right`} />
        </Link>
    )
}

function DeleteEvent({ onDelete }) {
  
    async function onDeleteWrapper() {
      await onDelete()
      toast.success("删除成功")
    }
  
    return (
        <button className="btn btn-sm" onClick={onDeleteWrapper}>
            <i className="fa fa-trash-can"></i>
        </button>
    );
}

function RegenEvent({ onRegen }) {
  
    return (
        <button className="btn btn-sm" onClick={onRegen}>
            <i className="fa-solid fa-arrow-rotate-left"></i>
        </button>
    );
}

function ChatEntry(props) {
    const { entry, prevEntry, character, deleteEvent, regenEvent, isLast } = props
    const isAI = entry.role === "assistant"
    const isSystem = entry.role === "system"
    const showRating = entry.type === "broadcast"
    const [showMenu, setShowMenu] = useState(false);
    if (isSystem) return null

    var hasLag = true
    if (prevEntry) {
        if (entry.date - prevEntry.date < 10 * 60 * 1000) {
            hasLag = false
        }
    }

    const rating = entry.rating

    const dateBadge = () => {
        if (!hasLag || !entry.date) {
            return null
        }
        return (
            <div className="flex justify-center items-center p-5">
                <div className="badge badge-ghost">
                    {dateToText(entry.date)}
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="px-4 py-2">
            {dateBadge()}
            {
                <div className={`chat ${isAI ? "chat-start" : "chat-end"}`}>
                    {isAI ? <Avatar character={character} /> : null}
                    <div className={`break-words max-w-full chat-bubble ${entry.role === "assistant" ? "chat-bubble-primary" : "chat-bubble-neutral"}`}
                        onDoubleClick={()=>setShowMenu(!showMenu)}>
                        <Markdown>
                            {entry.content}
                        </Markdown>
                    </div>
                </div>
            }
            <div className="px-16 flex flex-row space-x-4">
                {(isAI && showRating) ? <RatingEntry rating={rating} eventId={entry.id} /> : null}
                {isAI && isLast && entry.id && !entry["broadcast_id"] && <RegenEvent onRegen={regenEvent} />}
                {isAI && isLast && entry.id && <DeleteEvent onDelete={deleteEvent} />}
                {isAI && entry["broadcast_id"] && <GoToBroadcast topic_id={entry["broadcast_id"]} />}
            </div>
            {entry.mood >= 15 && <div className="px-16"><span className="badge badge-ghost">{`❤️${character?.name}的心情变好了！`}</span></div>}
            </div>
        </div>
    )
}

export default ChatPage;
