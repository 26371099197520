import { useEffect, useState } from "react";
import { createBroadcast, createBroadcastTopic, deleteBroadcast, queryBroadcast, queryBroadcastTopics } from "../utils/api.ts";
import React from "react";
import NavbarContainer from "../NavbarContainer.tsx";
import { useUser } from "../context/UserContext.tsx";
import Markdown from "../common/markdown.tsx";

type Broadcast = { id: number, author_id: number, name: string, description: string }
type BroadcastProps = { broadcasts: Broadcast[] }
type BroadcastTopic = { id: number, author_id: number, title: string, content: string }

function CreateBroadcastTopic({ broadcasts }: BroadcastProps) {

  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  const [selectedBroadcast, setSelectedBroadcast] = useState(broadcasts[0].id);
  const [topics, setTopics] = useState<BroadcastTopic[]>([])

  const create_topic_invalid = title == "" || content == ""

  const loadTopics = async () => {
    if (!selectedBroadcast) {
      return
    }
    const topics = await queryBroadcastTopics(selectedBroadcast)
    console.log(topics)
    setTopics(topics)
  }

  useEffect(() => {
    loadTopics()
  }, [selectedBroadcast]);

  const confirmCreateTopic = async () => {
    if (create_topic_invalid) return false
    const id = await createBroadcastTopic(selectedBroadcast, title, content, true, null)
  }

  return (
    <div className="card card-body bg-white">
      <p>Selected Broadcast</p>
      <select className="select select-bordered" value={selectedBroadcast} onChange={e => setSelectedBroadcast(Number(e.target.value))}>
        {broadcasts.map((option, index) => (
          <option key={index} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <input
        className="input input-bordered w-full max-w-xs"
        placeholder="Broadcast Name"
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <p>{"::generate-image[1girl, on beach]{在海边的自拍}给你看看我最近拍的照片好看吗"}</p>
      <textarea
        className="textarea textarea-bordered h-32"
        placeholder="Broadcast Description"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <Markdown>{content}</Markdown>
      <button onClick={confirmCreateTopic} className="btn btn-primary" disabled={create_topic_invalid}>Create Topic</button>
      <p>past topics:</p>
      {topics.map((topic, index) => (
        <div key={index} >
          <p>{topic.title}</p>
          <p>{topic.content}</p>
        </div>
      ))}
    </div>
  )
}

export default function BroadcastCreatePage() {

  const [broadcasts, setBroadcasts] = useState<Broadcast[]>([]);
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const { user, loading } = useUser();

  const loadMyBroadcast = async () => {
    if (user === null) {
      return
    }
    const broadcasts = await queryBroadcast(user.id)
    console.log(broadcasts)
    setBroadcasts(broadcasts)
  }

  useEffect(() => {
    loadMyBroadcast()
  }, [user]);

  const create_broadcast_invalid = name == "" || description == ""

  const confirmCreateBroadcast = async () => {
    if (create_broadcast_invalid) return false
    const id = await createBroadcast(name, description)
    if (id) {
      await loadMyBroadcast()
    }
  }

  const confirmDeleteBroadcast = async (id: number) => {
    await deleteBroadcast(id)
    await loadMyBroadcast()
  }

  return (
    <NavbarContainer title="我的Broadcast">
      <div className="card card-body bg-white">
        <p>我创建的Broadcast:</p>
        {broadcasts.map((broadcast, index) => (
          <div key={index} className="flex flex-row p-2 bordered">
            <b>{broadcast.name}</b>:
            <p>{broadcast.description}</p>
            <button className="btn" onClick={() => confirmDeleteBroadcast(broadcast.id)}>删除</button>
          </div>
        ))}
        <input
          className="input input-bordered w-full max-w-xs"
          placeholder="Broadcast Name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <textarea
          className="textarea textarea-bordered h-32"
          placeholder="Broadcast Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <button onClick={confirmCreateBroadcast} className="btn btn-primary" disabled={create_broadcast_invalid}>Create Broadcast</button>
      </div>
      {broadcasts.length && <CreateBroadcastTopic broadcasts={broadcasts} />}
    </NavbarContainer>
  );
}