import React, { useEffect } from 'react';
import { useCharacter } from '../context/CharacterContext.tsx';
import { Link } from 'react-router-dom';
import { prettifyContent } from '../utils/content.ts';

export default function CharacterChats() {

    const {linkedCharacters, fetchLinkedCharacterAsync} = useCharacter()
    useEffect(()=> {
        fetchLinkedCharacterAsync()
    }, [])

    const lastLocalMessage = (charID: string) => {
        const historyLSID = `history-${charID}`
        const localHistory = localStorage.getItem(historyLSID);
        const history = localHistory !== null ? JSON.parse(localHistory) : [{"content":""}];
        if (history && history.length) {
            return prettifyContent(history[history.length - 1])
        }
        return []
    }

    return (
        <div className="w-full">
            <div className="flex flex-col">
                {linkedCharacters.map((character, index) => (
                    <div className="w-full" key={index}>
                        <Link to={`/c/${character.id}`} className="bg-base-100 flex flex-row px-2 py-4 border-b-[1px] border-slate-200">
                            <img className="w-20 h-20 rounded-full" src={character.avatar_url} alt={`Avatar of ${character.name}`} />
                            <div className="flex flex-col p-3">
                                <div className="line-clamp-1 text-lg">
                                    <b>{character.name}</b>
                                </div>
                                <div className="line-clamp-1 text-slate-500">
                                    {lastLocalMessage(character.id)}
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};