
import Cookies from 'js-cookie';
import toast from "react-hot-toast"
import { Character, EmptyCharacter, User } from './type.ts';

export async function fetchInfoAPI(): Promise<User | null> {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/user/info`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
      }
    });

    if (res.status === 401) {
      return null
    }

    if (res.status !== 200) {
      toast.error("获取用户信息失败🙅");
      return null;
    }

    const data = await res.json();

    return data;
  } catch (e) {
    toast.error("获取用户信息失败🙅");
    return null;
  }
}

export async function updateInfoAPI(nickname: string): Promise<User | null>  {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/user/info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
      },
      body: JSON.stringify({
        nickname: nickname,
      })
    });

    if (res.status !== 200) {
      toast.error("更新用户信息失败🙅");
      return null;
    }
    toast.success("更新用户信息成功");
    return await res.json()
  } catch (e) {
    toast.error("更新用户信息失败🙅");
    return null
  }
}

export async function fetchCharacterAPI(charID: string): Promise<Character | null> {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${charID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
      }
    });

    if (res.status === 401) {
      return null
    }

    if (res.status === 404) {
      return EmptyCharacter
    }

    if (res.status !== 200) {
      toast.error("获取角色信息失败🙅");
      return null
    }

    const data = await res.json();
    return data
  } catch (e) {
    toast.error("获取角色信息失败🙅");
    return null
  }
}

export async function agentChat(messages: any[], charName: string, model: number, moodOn: boolean, toLogin: ()=>void): Promise<any> {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/agent/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
      },
      body: JSON.stringify({
        "messages": [messages[messages.length - 1]],
        "character": charName,
        "model": model,
        "mood": moodOn,
      }),
    })
    const status = await res.status;
    if (status != 200) {
      if (status == 429) {
        toast.error("Ta好像有点累了，待会儿再来聊吧")
        return null
      }
      toast.error("没有登录哟🙅")
      toLogin()
      return null
    }
    return await res.json()
  } catch (e) {
    toast.error("暂时无法发送🙅")
    return null
  }
}

export async function agentChatStream(messages: any[], charName: string, model: number, processChunk: (chunk: string)=>Promise<void>, toLogin: ()=>void) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/agent/chat/stream`, {
      method: "POST",
      headers: {
        "Accept": "text/event-stream",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
      },
      body: JSON.stringify({
        "messages": [messages[messages.length - 1]],
        "character": charName,
        "model": model,
      }),
    })
    if (!res.body) return
    const reader = res.body.getReader();
    const decoder = new TextDecoder('utf-8');
    // Process each chunk of data as it comes in
    while (true) {
        const { value, done } = await reader.read();
        if (done) {
            break;
        }
        // Decode and process the chunk
        const chunk = decoder.decode(value, { stream: true });
        await processChunk(chunk); // Do something with the chunk
    }
  } catch (e) {
    toast.error("暂时无法发送🙅")
    return
  }
}

export interface CharacterRequest {
  name: string;
  gender: string;
  personality: string;
  avatar_url: string;
  portrait_url: string;
  appearance: string;
  appearance_prompt: string;
  bio: string,
  public: boolean,
  greeting: string
}

export async function createCharacterAPI(character: CharacterRequest, toLogin: ()=>void) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/characters`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
      },
      body: JSON.stringify(character)
    });

    if (res.status === 401) {
      toLogin()
      return null
    }

    if (res.status !== 200) {
      toast.error("创建角色失败🙅");
      return null
    }

    const data = await res.json();
    return data
  } catch (e) {
    toast.error("创建角色失败🙅");
    return null
  }
}

export async function writerFetchCharacterAPI(id: string, toLogin: ()=>void) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/writing/characters/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
      },
    });

    if (res.status === 401) {
      toLogin()
      return null
    }

    if (res.status !== 200) {
      toast.error("获取角色失败🙅");
      return null
    }

    const data = await res.json();
    return data
  } catch (e) {
    toast.error("获取角色失败🙅");
    return null
  }
}

export async function writerListCharacterAPI(toLogin: ()=>void): Promise<{id: string, starve: boolean}[]> {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/writing/characters`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
      },
    });

    if (res.status === 401) {
      toLogin()
      return []
    }

    if (res.status !== 200) {
      toast.error("获取角色失败🙅");
      return []
    }

    const data = await res.json();
    console.log(data)
    return data["characters"]
  } catch (e) {
    toast.error("获取角色失败🙅");
    return []
  }
}

export async function writerUpdateCharacterAPI(id: string, extra_prompt: string, toLogin: ()=>void) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/writing/characters/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
      },
      body: JSON.stringify({
        extra_prompt: extra_prompt,
      })
    });

    if (res.status === 401) {
      toLogin()
      return
    }

    if (res.status !== 200) {
      toast.error("更新角色失败🙅");
      return
    }

    return
  } catch (e) {
    toast.error("更新角色失败🙅");
    return
  }
}


export async function writerCreateTopicAPI(character_id: string,
  title: string, content: string, followup: string, triggerAt: string, triggerType: string, toLogin: ()=>void) {
  try {
    const body = {
      title: title,
      content: content,
    }
    if (followup.length > 0) {
      body["followup"] = {
        content: followup,
        trigger_at: new Date(triggerAt).toISOString(),
        trigger_type: triggerType,
      }
    }
    console.log(body)
    const res = await fetch(`${process.env.REACT_APP_API_URL}/writing/characters/${character_id}/topics`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
      },
      body: JSON.stringify(body)
    });

    if (res.status === 401) {
      toLogin()
      return
    }

    if (res.status !== 200) {
      toast.error("创建话题失败🙅");
      return
    }

    toast.success("创建话题成功");
    return
  } catch (e) {
    toast.error("创建话题失败🙅");
    return
  }
}

export async function generateImage(prompt: string, refImage: string | null) : Promise<string[]> {
  var body = {
    "prompt": prompt,
    "negative_prompt": "(worst quality, low quality:2),lowres, bad anatomy, bad hands, text, error, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry,lowres,worst quality,low quality,normal quality,duplicate,grossproportions,deformed,out of frame,60s,70s,80s,90s,00s,",
    "height": 512,
    "weight": 512,
    "outscale": 1.5,
    "ip_adapter_scale": 0.75,
    "cross_attention_scale": 0.5,
    "num_images": 1,
    "seed": 0,
  }
  if (refImage) {
    body["ref_image"] = refImage
  }
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/resource/generate/images/complete`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
        },
        body: JSON.stringify(body),
    })
    if (res.status !== 200) {
      toast.error("图片创建失败🙅");
      return []
    }
    const data = await res.json()
    return data["image_list"]
  } catch (e) {
      toast.error("图片创建失败🙅")
      return []
  }
}

export async function uploadResource(file: File, toLogin: ()=>void): Promise<string> {
  if (file.size >= 1048576 * 10) {
    toast.error("文件太大🙅");
    return ""
  }
  const data = new FormData();
  data.append('file', file);
  var res = await fetch(`${process.env.REACT_APP_API_URL}/resource`, {
    method: 'POST',
    body: data,
    headers: {
      "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
    },
  });

  if (res.status === 401) {
    toLogin()
    return ""
  }

  if (res.status !== 200) {
    toast.error("上传失败🙅");
    return ""
  }

  toast.success("上传成功");
  const content = await res.json()
  return content["url"]
}

export async function writerCreateScene(character_id: string, idea: string, toLogin: ()=>void): Promise<any> {
  var res = await fetch(`${process.env.REACT_APP_API_URL}/writing/characters/${character_id}/scenes/generate`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
    },
    body: JSON.stringify({
      "idea": idea,
    })
  });

  if (res.status === 401) {
    toLogin()
    return null
  }

  if (res.status !== 200) {
    toast.error("生成失败🙅");
    return null
  }

  toast.success("生成成功");
  const content = await res.json()
  return content
}

export async function writerCreateIM(character_id: string, topic: string, toLogin: ()=>void): Promise<string> {
  var res = await fetch(`${process.env.REACT_APP_API_URL}/writing/characters/${character_id}/im/generate`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
    },
    body: JSON.stringify({
      "topic": topic,
    })
  });

  if (res.status === 401) {
    toLogin()
    return ""
  }

  if (res.status !== 200) {
    toast.error("生成失败🙅");
    return ""
  }

  toast.success("生成成功");
  const content = await res.json()
  return content["message"]
}

export async function writerCreateMonologue(character_id: string, topic: string, toLogin: ()=>void): Promise<string> {
  var res = await fetch(`${process.env.REACT_APP_API_URL}/writing/characters/${character_id}/monologue/generate`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
    },
    body: JSON.stringify({
      "topic": topic,
    })
  });

  if (res.status === 401) {
    toLogin()
    return ""
  }

  if (res.status !== 200) {
    toast.error("生成失败🙅");
    return ""
  }

  toast.success("生成成功");
  const content = await res.json()
  return content["message"]
}

export async function ttsLong(character_id: string, text: string): Promise<string> {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${character_id}/tts_long`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
    },
    body: JSON.stringify({
      text: text
    })
  });
  if (res.status !== 200) {
    toast.error("生成失败🙅");
    return ""
  }
  const data = await res.json()
  return data["audio"]
}

export async function queryBroadcast(author_id: number) {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/source?author_id=${author_id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
    },
  });
  if (res.status !== 200) {
    toast.error("生成失败🙅");
    return []
  }
  const data = await res.json()
  return data["sources"]
}

export async function createBroadcast(name: string, description: string): Promise<null | number> {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/source`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
    },
    body: JSON.stringify({
      name: name,
      description: description
    })
  });
  if (res.status !== 200) {
    toast.error("创建失败🙅");
    return null
  }
  const data = await res.json()
  const id = data["id"]
  if (id) {
    toast.success("创建成功🙅");
  }
  return id
}

export async function deleteBroadcast(source_id: number) {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/source/${source_id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
    }
  });
  if (res.status !== 200) {
    toast.error("删除失败🙅");
    return null
  }
}

export async function createBroadcastTopic(source_id: number, title: string, content: string, isPublic: boolean, character_id: number|null): Promise<null | number> {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/source/${source_id}/topics`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
    },
    body: JSON.stringify({
      title: title,
      content: content,
      public: isPublic,
      character_id: character_id
    })
  });
  if (res.status !== 200) {
    toast.error("创建失败🙅");
    return null
  }
  const data = await res.json()
  const id = data["id"]
  return id
}

export async function queryBroadcastTopics(source_id: number) {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/source/${source_id}/topics`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
    }
  });
  if (res.status !== 200) {
    return null
  }
  const data = await res.json()
  return data["topics"]
}

export async function getCharacterBroadcasts(character_id: string) {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${character_id}/broadcasts?limit=5`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
    }
  });
  if (res.status !== 200) {
    return null
  }
  const data = await res.json()
  return data["topics"]
}

export async function cookCharacterBroadcast(character_id: string, broadcast_id: number, rewrite: boolean) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${character_id}/broadcasts/${broadcast_id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${Cookies.get('ringriseusertoken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rewrite: rewrite,
      })
    });
    if (res.status !== 200) {
      return null
    }
    const data = await res.json()
    return data
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function deleteCharacterEvent(character_id: string, event_id: number) {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${character_id}/history/${event_id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${Cookies.get('ringriseusertoken')}`,
    },
  });
  if (res.status !== 200) {
    return false
  }
  return true
}

export async function getUserAlbum() {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/user/album`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
    },
  });
  if (res.status !== 200) {
    return null
  }
  const data = await res.json()
  return data["image_urls"]
}