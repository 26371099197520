import React from "react"
import ReactMarkdown from 'react-markdown'
import Zoom from 'react-medium-image-zoom'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {vscDarkPlus} from 'react-syntax-highlighter/dist/esm/styles/prism'

export default function Markdown(props) {
    const markdownComponents = {
        em(props) {
            const { node, ...rest } = props
            return <i className="text-secondary" {...rest} />
        },
        a(props) {
            return <a href={props.href} target="_blank" className="underline text-cyan-600">
                {props.children[0]}
            </a>
        },
        img(props) {
            const { node, ...rest } = props
            return <div className="py-2">
                <Zoom>
                    <img className="rounded h-48" {...rest} />
                </Zoom>
            </div>
        },
        code(props) {
            const {children, className, node, ...rest} = props
            const match = /language-(\w+)/.exec(className || '')
            return match ? (
              <SyntaxHighlighter
                className="rounded"
                {...rest}
                PreTag="div"
                children={String(children).replace(/\n$/, '')}
                language={match[1]}
                style={vscDarkPlus}
              />
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            )
        },
    }
    return (
        <ReactMarkdown components={markdownComponents}>
            {props.children.replace("\n", "\n\n")}
        </ReactMarkdown>
    )
}

