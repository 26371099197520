import './App.css';
import ChatPage from './chat/ChatPage.tsx';
import HomePage from "./HomePage.tsx"
import { BrowserRouter, Routes, Route, Navigate, Link, Outlet, useLocation } from "react-router-dom";
import SettingPage from './SettingPage.tsx';
import LoginPage from './LoginPage.tsx'
import RegisterPage from './RegisterPage.tsx'
import PremiumPage from './PremiumPage.tsx';
import WriterPage from './writing/WriterPage.tsx';
import { Toaster } from "react-hot-toast"
import CharacterCreator from './character/CharacterCreationPage.tsx';
import ASMRPage from './asmr/ASMRPage.tsx';
import WriterCharactersPage from './writing/WriterCharactersPage.tsx';
import React from 'react';
import { CharacterProvider } from './context/CharacterContext.tsx';
import { UserProvider, useUser } from './context/UserContext.tsx';
import CharacterPage from './character/CharacterPage.tsx';
import BroadcastCreatePage from './broadcast/BroadcastCreatePage.tsx';
import BroadcastListPage from './broadcast/BroadcastListPage.tsx';
import BroadcastTopicPage from './broadcast/BroadcastTopicPage.tsx';
import CharacterChats from './character/CharacterChats.tsx';

function NavbarContainer() {
  
  const location = useLocation(); // Gets the current location

  // Function to check if the path matches the current location
  const isActive = (path) => location.pathname === path;

  return (
    <div className="drawer md:drawer-open">
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      {/* dvh doesn't work on HUAWEI, vh doesn't work on iphone*/}
      <div className="drawer-content flex flex-col bg-gradient-to-t from-[#ccd4f8] via-[#f4ebfb] to-white h-screen">
        <div className="h-full overflow-y-auto no-scrollbar">
          <div className="pb-32 pt-[56px] ">
            <Outlet />
          </div>
        </div>
      </div>
      <div className="btm-nav md:hidden">
        <Link to="/home" className={isActive('/home') ? 'active' : ''}>
          <div className="btm-nav-label">主页</div>
        </Link>
        <Link to="/chat" className={isActive('/chat') ? 'active' : ''}>
          <div className="btm-nav-label">聊天</div>
        </Link>
        <Link to="/me" className={isActive('/me') ? 'active' : ''}>
          <div className="btm-nav-label">我的</div>
        </Link>
      </div>
      <div className="drawer-side z-[999]">
        <label htmlFor="my-drawer-3" aria-label="close sidebar" className="drawer-overlay"></label>
        <ul className="menu p-4 w-40 min-h-full bg-base-200">
          {/* Sidebar content here */}
          <li> <Link to="/home">主页</Link></li>
          <li> <Link to="/chat">聊天</Link></li>
          <li> <Link to="/me">我的</Link></li>
        </ul>
      </div>
    </div>
  );
}

const ProtectedRoute = () => {
  const { user, loading } = useUser();
  const unauthed = user === null && !loading;

  if (unauthed) {
    // If user not logged in, redirect to login page
    return <Navigate to="/login" replace />;
  }

  return <Outlet />; // Render children routes if authenticated
};

function App() {
  return (
    <UserProvider>
      <CharacterProvider>
        <BrowserRouter>
          <Toaster />
          <Routes>
            <Route path="/" element={<NavbarContainer />}>
              <Route path="home" element={<HomePage />} />
              <Route path="/" element={<Navigate to="home" />} />
              <Route element={<ProtectedRoute />}>
                <Route path="me" element={<SettingPage />} />
                <Route path="chat" element={<CharacterChats />} />
                <Route path="premium" element={<PremiumPage />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="c/:charID" element={<ChatPage />} />
              <Route path="character/:charID" element={<CharacterPage />} />
              <Route path="character/create/:charID" element={<CharacterCreator />} />
              <Route path="writing/characters" element={<WriterCharactersPage />} />
              <Route path="writing/characters/:characterID" element={<WriterPage />} />
              <Route path="broadcasts/create" element={<BroadcastCreatePage />} />
              <Route path="broadcasts" element={<BroadcastListPage />} />
              <Route path="broadcasts/topics/:topic_id" element={<BroadcastTopicPage />} />
            </Route>
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="assets/asmr" element={<ASMRPage />} />
          </Routes>
        </BrowserRouter>
      </CharacterProvider>
    </UserProvider>
  );
}

export default App;
