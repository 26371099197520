import Cookies from "js-cookie";

if (navigator.serviceWorker) {
    // only init on PWA
    if ((navigator as any).standalone) {
        initServiceWorker();
    }
} else {
    console.log("serviceWorker undefined")
}

async function initServiceWorker() {

    let swRegistration = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/serviceWorker.js`)
    let pushManager = swRegistration.pushManager;

    if (!pushManager) {
        console.log("pushManager undefined")
        return;
    }

    let permissionState = await pushManager.permissionState({userVisibleOnly: true});
    switch (permissionState) {
        case 'prompt':
            console.log('prompt')
            break;
        case 'granted':
            console.log(await pushManager.getSubscription())
            break;
        case 'denied':
            console.log('denied')
            break
    }
}

export async function subscribeToPush() {

       // only run on PWA
    if (!(navigator as any).standalone) {
        return
    }

    if (!navigator.serviceWorker) {
        console.log("serviceWorker undefined")
        return
    }

    // All subscription tokens associated with this key, so if you change it - you may lose old subscribers
    const VAPID_PUBLIC_KEY = "BBuAG6cSER1cN0nTcp67nj5a94snWVXpeWRt6fR91cTAP55llOCIoD8VJ-gjbf-sh9-j7IGlHwJp3uthKEus19Q";
    let swRegistration = await navigator.serviceWorker.getRegistration();
    if (!swRegistration) {
        console.log("registration undefined")
        return;
    };
    let pushManager = swRegistration.pushManager;
    if (!pushManager) {
        console.log("pushManager undefined")
        return;
    }
    let subscriptionOptions = {
        userVisibleOnly: true,
        applicationServerKey: VAPID_PUBLIC_KEY
    };
    try {
        let subscription = await pushManager.subscribe(subscriptionOptions);
        console.log(subscription);
        const res = await fetch(`${process.env.REACT_APP_API_URL}/webpush/sub`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
            },
            body: JSON.stringify({
                web_subscription: JSON.stringify(subscription)
            })
        });
        if (res.status !== 200) {
            console.log("failed to subscribeToPush")
            return
        }
    } catch (error) {
        console.log(error)
    }
}