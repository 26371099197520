import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as PlayIcon } from './btn_play.svg';
import { ReactComponent as PauseIcon } from './btn_pause.svg';
import { useSearchParams } from "react-router-dom";


var audioContext: AudioContext | null = null;

const AudioPlayer = (props) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0.1);
    const [currentTime, setCurrentTime] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const audioRef = useRef<HTMLAudioElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const togglePlayPause = () => {
        if (audioRef.current && canvasRef.current) {
            if (audioContext === null) {
                audioContext = new AudioContext()
                startCanvasControl(audioContext, audioRef.current, canvasRef.current)
            }
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const onTimeUpdate = () => {
        if (audioRef.current && !isDragging) {
            setCurrentTime(audioRef.current.currentTime);
        }
    };

    const onProgressBarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (audioRef.current) {
            const time = (audioRef.current.duration / 100) * parseInt(e.target.value);
            audioRef.current.currentTime = time;
            setCurrentTime(time);
        }
    };

    const onDragStart = () => {
        setIsDragging(true);
        if (isPlaying) {
            audioRef.current?.pause();
        }
    };

    const onDragEnd = () => {
        setIsDragging(false);
        if (isPlaying) {
            audioRef.current?.play();
        }
    };

    const onEnded = () => {
        setIsPlaying(false);
    }

    const onLoadedMetadata = () => {
        if (audioRef.current && canvasRef.current) {
            setDuration(audioRef.current.duration);
        }
    };

    const startCanvasControl = (context: AudioContext, audio: HTMLAudioElement, canvas: HTMLCanvasElement) => {
        var src = context.createMediaElementSource(audio);
        var analyser = context.createAnalyser();
  
        var ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  
        src.connect(analyser);
        analyser.connect(context.destination);
  
        analyser.fftSize = 256;
  
        var bufferLength = analyser.frequencyBinCount;
  
        var dataArray = new Uint8Array(bufferLength);
  
        var WIDTH = canvas.width;
        var HEIGHT = canvas.height;
  
        var barWidth = (WIDTH / bufferLength);
        var barHeight;
        var x = 0;
  
        function renderFrame() {
            requestAnimationFrame(renderFrame);
    
            analyser.getByteFrequencyData(dataArray);
    
            ctx.clearRect(0, 0, WIDTH, HEIGHT);
    
            x = 0;
            
            for (var i = 0; i < bufferLength; i++) {
                barHeight = dataArray[i]/1.5;
                var gradient = ctx.createRadialGradient(x + barWidth / 2, (HEIGHT - barHeight) / 2 + barHeight / 2, 0, x + barWidth / 2, (HEIGHT - barHeight) / 2 + barHeight / 2, barHeight / 2);

                gradient.addColorStop(0, 'rgb(255, 255, 255, 0.8)');
                gradient.addColorStop(0.5, 'rgb(255, 255, 255, 0.8)');
                gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

                ctx.fillStyle = gradient;
                ctx.fillRect(x, (HEIGHT - barHeight)/2, barWidth, barHeight);
        
                x += barWidth + 3;
            }
      }
      renderFrame();
    }

    // Format time from seconds to mm:ss
    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className="flex flex-col w-full items-center">
            <div className="flex flex-col w-full items-center px-5 space-x-2">
                <audio
                    ref={audioRef}
                    crossOrigin="anonymous"
                    onTimeUpdate={onTimeUpdate}
                    onLoadedMetadata={onLoadedMetadata}
                    onEnded={onEnded}
                >
                    <source src={props.src} type="audio/mpeg"/>
                </audio>
                <input
                    type="range"
                    min="0"
                    max="100"
                    value={(currentTime / duration) * 100}
                    onChange={onProgressBarChange}
                    onMouseDown={onDragStart}
                    onMouseUp={onDragEnd}
                    onTouchStart={onDragStart}
                    onTouchEnd={onDragEnd}
                    className="w-full range range-xs range-primary"
                />
                <div className="flex justify-between w-full p-1 text-primary">
                    <span>{formatTime(currentTime)}</span>
                    <span>-{formatTime(duration - currentTime)}</span>
                </div>
                <button onClick={togglePlayPause} className="p-6">
                    {isPlaying ? <PauseIcon className="w-24 h-24"/> : <PlayIcon className="w-24 h-24"/>}
                </button>
            </div>
            <canvas ref={canvasRef} className="h-24 w-full py-2"></canvas>
        </div>
    );
};

export default function ASMRPage() {

    let [searchParams, setSearchParams] = useSearchParams();
  
    const audioURL = searchParams.get("audio") || "/test/test.mp3";
    const bgURL = searchParams.get("bg") || "/test/test.jpg";

    useEffect(() => {
        // Save the current overscroll-behavior
        const originalStyle = window.getComputedStyle(document.body).overscrollBehavior;
    
        // Set overscroll-behavior to none
        document.body.style.overscrollBehavior = 'none';
    
        // Reset overscroll-behavior when the component is unmounted
        return () => {
          document.body.style.overscrollBehavior = originalStyle;
        };
      }, []);

    return (
        <div className="flex justify-center bg-gradient-to-t from-[#ccd4f8] via-[#f4ebfb] to-white">
            <div className="relative w-full md:w-1/2">
                {/* Background Image */}
                <div
                    style={{backgroundImage: `url(${bgURL})`}} 
                    className={`absolute inset-0 bg-cover bg-center`}>
                </div>

                {/* Black Veil with 25% Transparency */}
                <div className="absolute inset-0 bg-black opacity-25"></div>

                {/* Content */}
                <div className="relative z-10">
                    <div className="flex flex-col h-screen justify-end items-center">
                        <div className="w-full py-5">
                            <AudioPlayer src={audioURL} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}