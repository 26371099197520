import React, { useEffect, useRef, useState } from 'react';
import { createCharacterAPI, fetchCharacterAPI, generateImage, uploadResource } from '../utils/api.ts';
import { useNavigate, useParams } from 'react-router-dom';
import AvatarCropper from '../AvatarCropper.tsx';
import NavbarContainer from '../NavbarContainer.tsx';
import Zoom from 'react-medium-image-zoom'
import toast from 'react-hot-toast';
import { Character } from '../utils/type.ts';
import LoadingPage from '../LoadingPage.tsx';

const questions = [
    {
        id: 'name',
        label: '昵称',
        placeHolder: '小夕',
        maxLen: '20'
    },
    {
        id: 'gender',
        label: '性别',
        options: ['男', '女'],
    },
    {
        id: 'bio',
        label: '角色简介',
        placeHolder: '角色的简单描述（角色公开时用来帮助其他用户认识这个角色）',
        maxLen: '500',
        textArea: true,
    },
    {
        id: 'personality',
        label: '性格',
        placeHolder: '温柔体贴，但是有时候会很任性',
        maxLen: '50',
    },
    {
        id: 'extra_prompt',
        label: '详细设定',
        placeHolder: '说话风格、爱好、背景故事等。',
        maxLen: '1000',
        textArea: true,
    },
    {
        id: 'greeting',
        label: '开场白',
        placeHolder: '角色聊天时说的第一句话。',
        maxLen: '1000',
        textArea: true,
    },
]

function CharacterCreator() {
    const [choices, setChoices] = useState({
        "public": true
    });
    // avatar related
    const [showAvatarCropper, setShowAvatarCropper] = useState(false);
    const [avatar, setAvatar] = useState("")
    const [portraitSrc, setPortraitSrc] = useState("")
    const [portraitLoading, setPortraitLoading] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)

    const{ charID } = useParams()

    const [character, setCharacter] = useState<Character | null>(null);

    const isNewCharacter = charID === "0"
    useEffect(() => {
        const initializeCharacter= async () => {
            // new character creation
            if (isNewCharacter) {
                return
            }
            const character = await fetchCharacterAPI(charID || "")
            setCharacter(character)
        }
        initializeCharacter()
    }, [])

    const imageGenerateDialogRef = useRef<null | HTMLDialogElement>(null)
    const confirmCreationDialogRef = useRef<null | HTMLDialogElement>(null)

    useEffect(()=>{
        if (character != null) {
            setChoices({
                "gender": character.gender,
                "appearance": character.appearance,
                "name": character.name,
                "personality": character.personality,
                "extra_prompt": character.extra_prompt,
                "greeting": character.greeting,
                "public": character.public,
                "bio": character.bio
            })
            setAvatar(character.avatar_url)
            setPortraitSrc(character.portrait_url)
        }
    }, [character])

    const navigate = useNavigate()

    if (!isNewCharacter && !character) {
        return <LoadingPage />
    }

    const handleInpntChange = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        //event.target.name
        setChoices({
            ...choices,
            [event.target.name]: event.target.value
        });
    };

    const handleOptionSelect = (question, option: string) => {
        if (question.multiple) {
            // For multiple selection, toggle the option in an array
            setChoices({
                ...choices,
                [question.id]: choices[question.id] ?
                    choices[question.id].includes(option)
                        ? choices[question.id].filter(item => item !== option)
                        : [...choices[question.id], option]
                    : [option],
            });
        } else {
            setChoices({ ...choices, [question.id]: option });
        }
    };
    
    const onAvatarCropped = (avatar) => {
        setAvatar(avatar)
        setShowAvatarCropper(false);
    }
    
    const appearancePrompt = (appearance) => ((choices["gender"] === "男") ? "1male,": "1girl,") + appearance + ",best quality,masterpiece"
    
    const generatePortraitImage = async() => {
      const appearance = choices["appearance"]
      if ( portraitLoading || !appearance) {
        return
      }
      setAvatar("")
      setPortraitSrc("")
      setPortraitLoading( true )
      const prompt = appearancePrompt(appearance)
      const images = await generateImage(prompt, null);
      console.log(images)
      if (images.length > 0) {
        setPortraitSrc(images[0])
        setPortraitLoading(false)
      }
      setPortraitLoading(false)
    };

    const confirmCreation = async () => {
        if ( confirmLoading ) {
            return
        }
        setConfirmLoading(true)

        var avatarURL = avatar
        // upload Avatar
        if (avatar.startsWith("data")) {
            let blob = await (await fetch(avatar)).blob();
            let fileName = "avatar.png";
            let file = new File([blob], fileName, { type: blob.type });
            avatarURL = await uploadResource(file, ()=> {})
            if (!avatarURL) {
                setConfirmLoading(false)
                return
            }
        }

        // upload portrait if needed
        var portraitURL = portraitSrc
        if (portraitSrc.startsWith("blob:")) {
            let blob = await (await fetch(portraitSrc)).blob();
            let fileName = "portrait";
            let file = new File([blob], fileName, { type: blob.type });
            const serverSrc = await uploadResource(file, ()=> {})
            if (!serverSrc) {
                setConfirmLoading(false)
                return
            }
            portraitURL = serverSrc
            setPortraitSrc(portraitURL)
        }
        const characterToPost = {
            id: character?.id,
            name: choices["name"],
            gender: choices["gender"],
            personality: choices["personality"],
            avatar_url: avatarURL,
            portrait_url: portraitURL,
            appearance: choices["appearance"] || "",
            appearance_prompt: appearancePrompt(choices["appearance"]),
            extra_prompt: choices["extra_prompt"],
            greeting: choices["greeting"],
            public: choices["public"],
            bio: choices["bio"],
        }
        console.log(characterToPost)
        const data = await createCharacterAPI(characterToPost, ()=>{navigate("/login")})
        console.log(data)
        if (!data) {
            toast.error("创建角色失败")
            setConfirmLoading(false)
            return
        }
        const id = data["id"]
        setConfirmLoading(false)

        if (id) {
            if (isNewCharacter) {
                navigate(`/c/${id}?back=${encodeURIComponent("/chat")}`)
                return
            }
            navigate(-1)
        }
    }

    const handlePortraitImageUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
            const img = e.target.files[0];
            const url = URL.createObjectURL(img)
            console.log(url)
            setPortraitSrc(url);
        } else {
            URL.revokeObjectURL(portraitSrc)
            setPortraitSrc("")
        }
    }

    const confirmCreationDialog = () => {
        return (
            <dialog ref={confirmCreationDialogRef} className="modal">
                <div className="modal-box space-y-2">
                    {character?.id ? <h3 className="font-bold text-lg">确定要更新这个角色吗？</h3>:  <h3 className="font-bold text-lg">确定要创建这个新角色吗？</h3>}
                    <div className="flex flex-col space-y-2" >
                        <p>更改将对所有有权限的用户可见。</p>
                    </div>
                    <div className="modal-action">
                        <form method="dialog" className="space-x-4">
                            <button className="btn" onClick={confirmCreation} >确定</button>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }
    function showConfirmCreationDialog() {
        if (confirmCreationDialogRef && confirmCreationDialogRef.current) confirmCreationDialogRef.current.showModal()
    }

    const imageGeneratorDialog = () => {
        return (
            <dialog ref={imageGenerateDialogRef} className="modal">
                <div className="modal-box space-y-2">
                    <h3 className="font-bold text-lg">角色立绘</h3>
                    <div className="flex flex-col space-y-2" >
                        <p>用AI生成立绘</p>
                        <div className="flex flex-row space-x-4">
                            <input type="text" name="appearance" placeholder="" maxLength={100} value={choices["appearance"]}
                                onChange={handleInpntChange} className="input input-bordered w-full max-w-md" 
                            />
                            <button className="btn btn-primary" onClick={generatePortraitImage} disabled={portraitLoading}>
                                { portraitLoading ? <span className="loading loading-spinner"></span>: null}生成
                            </button> 
                        </div> 
                        <p>或者</p>
                        <label htmlFor="portrait-upload" className="btn btn-primary mt-4">上传图片</label>
                        <input id="portrait-upload" className="file-input mt-4" type="file" onChange={handlePortraitImageUpload} hidden/>
                    </div>
                    {portraitSrc ? <Zoom><img src={portraitSrc} alt="portrait" className="h-48 rounded" /></Zoom>: null}
                    <div className="modal-action">
                        <form method="dialog" className="space-x-4">
                            <button className="btn" >完成</button>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }

    function showImageGenerateDialog() {
        if (imageGenerateDialogRef && imageGenerateDialogRef.current) imageGenerateDialogRef.current.showModal()
    }

    const ImageGenerator = () => (
          <div className="space-y-4">  
            <div className="flex flex-row space-x-4 py-4">  
                <button className="h-36 w-36 border-dashed border-2 border-slate-400 rounded" onClick={showImageGenerateDialog}>
                    {portraitSrc ? <img src={portraitSrc} alt="portrait" className="h-36 rounded" />: "立绘"}
                </button>
                <button className="h-36 w-36 border-dashed border-2 border-slate-400 rounded-full" disabled={!portraitSrc} onClick={()=>setShowAvatarCropper(true)}>
                    {avatar ? <img src={avatar} alt="avatar" className="h-36 rounded-full" />: "头像"}
                </button>
                {imageGeneratorDialog()}
            </div>
            {showAvatarCropper && ( 
                <AvatarCropper 
                    src={portraitSrc}
                    setShow={setShowAvatarCropper}
                    onConfirm={onAvatarCropped}
                /> 
            )}
          </div>
    )

    const handleCheck = ()=> {
        console.log(choices["public"])
        setChoices({...choices, "public": !choices["public"]})
    }

    const publicChecker = () => (
        <div className="flex flex-row space-x-3 py-2">
            <input type="checkbox" checked={choices["public"]} onClick={handleCheck} className="checkbox checkbox-secondary" />
            <p>公开这个角色</p>
        </div>
    )

    const questionEntry = (question) => {
        if (question.options) {
            return (
                <div className="flex flex-row flex-wrap">
                    {question.options.map((option) => (
                        <div className="p-1">
                            <button
                                key={option}
                                className={`btn btn-outline btn-md ${choices[question.id]?.includes(option) ? 'btn-secondary' : 'btn-success'}`}
                                onClick={() => handleOptionSelect(question, option)}
                            >
                                {option}
                            </button>
                        </div>
                    ))}
                </div>
            )
        }
        if (question.textArea) {
            return (
                <textarea rows={4} name={question.id} placeholder={question.placeHolder} maxLength={question.maxLen} value={choices[question.id]} onChange={handleInpntChange} className="textarea textarea-bordered w-full max-w-md"></textarea>
            )
        }
        return (
            <input type="text" name={question.id} placeholder={question.placeHolder} maxLength={question.maxLen} value={choices[question.id]} onChange={handleInpntChange} className="input input-bordered w-full max-w-md" />
        )
    }

    const valid = () => {
        for (const question of questions) {
            if (!choices[question.id]) {
                return false
            }
        }
        return portraitSrc && avatar
    }
    
    return (
        <NavbarContainer title="创建角色">
            <div className="p-5">
                {questions.map((question) => (
                    <div className="flex flex-col">
                        <div className="label">{question.label}</div>
                        {questionEntry(question)}
                    </div>
                ))}
                {ImageGenerator()}
                {publicChecker()}
                <div className="flex flex-row space-x-4">
                    <button className="btn btn-primary mt-4" onClick={showConfirmCreationDialog}  disabled={!valid() || confirmLoading}>{ confirmLoading && <span className="loading loading-spinner"></span>}保存</button>
                </div>
                {confirmCreationDialog()}
            </div>
        </NavbarContainer>
    );
}

export default CharacterCreator;
