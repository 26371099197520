import React from "react";
import { useEffect, useState } from "react";
import NavbarContainer from "../NavbarContainer.tsx";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { prettifyContent } from "../utils/content.ts";

type Broadcast = {
    id: number;
    source_id: number;
    title: string;
    content: string
    author_username: string;
    count: number;
};

export default function BroadcastListPage() {
    const [topics, setTopics] = useState<Broadcast[]>([]);
    const [page, setPage] = useState(1);

    const loadBroadcastList = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/topics?page=${page}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json();
            setTopics(data["topics"])
        } catch (error) {
            toast.error("请稍后再试")
            return
        }
    }
                    
    useEffect(() => {
        setTopics([])
        loadBroadcastList()
    }, [page]);

    return (
        <NavbarContainer title="话题广场">
            <div className="min-h-screen py-5">
                <div className="">
                    {topics.map((topic) => (
                        <Link to={`/broadcasts/topics/${topic.id}`}>
                            <article key={topic.id} className="p-4 rounded-lg shadow">
                                <h2 className="text-lg font-semibold">{topic.title}</h2>
                                <div className="flex flex-row">
                                    <p className="text-left text-xs text-secondary mr-4 w-24">{`@${topic.author_username}`}</p>
                                    <i className="fa fa-pencil text-secondary text-xs mr-1"></i>
                                    <p className="text-left text-xs text-secondary">{topic.count}</p>
                                </div>
                                <div className="text-left text-sm overflow-hidden text-ellipsis whitespace-nowrap w-full">{
                                    prettifyContent(topic)
                                }</div>
                            </article>
                        </Link>
                    ))}
                </div>
                <div className="p-3 flex flex-row space-x-3 justify-center items-center" >
                    { page >1 &&<button className="btn btn-sm" onClick={()=>setPage(page-1)}>上一页</button> }
                    <p>第{page}页</p>
                    <button className="btn btn-sm" onClick={()=>setPage(page+1)}>下一页</button>
                </div>
            </div>
        </NavbarContainer>
    );
};