import React, { useRef, useEffect, useState } from "react";
import Avatar from 'react-avatar-edit'
import { uploadResource } from "./utils/api.ts";

const AvatarCropper = (props) => {
  const [preview, setPreview] = useState<RequestInfo|null>(null)
  const { src, onConfirm, setShow } = props

  const onCropConfirm = async () => {
    if (!preview) return
    onConfirm(preview)
  }

  const onCrop = (preview) => {
    setPreview(preview)
  }

  return (
    <div className="modal modal-open">
      <div className="modal-box space-y-4 p-4">
        <Avatar
          src={src.startsWith("http") ? src + "?not-from-cache-please": src}
          width={250}
          height={250}
          onCrop={onCrop}
          exportAsSquare={true}
          exportSize={390}
          onClose={() => {setShow(false)}}
         />
        <div className="flex space-x-4 justify-content-between">
          <button type="button" className="btn btn-primary flex-1" onClick={onCropConfirm}>
            确定
          </button>
          <button type="button" className="btn btn-primary flex-1" onClick={() => {setShow(false)}}>
            取消
          </button>
        </div>
      </div>
    </div>
  )
};

export default AvatarCropper;
