import React from "react";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

export default function PremiumPage() {

    const notYetModalID = "not-yet-read"

    const showModal = async (sku: string) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/premium`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
              },
              body: JSON.stringify({
                "sku": sku,
              }),
            })
          } catch (e) {
            toast.error("暂时无法发送🙅")
            return
          }
        const token = Cookies.get('ringriseusertoken')
        Cookies.set('ringriseusertoken', token, { expires: 7 })
        const modal = document.getElementById(notYetModalID) as HTMLDialogElement
        if (modal) modal.showModal()
    }

    return (
        <div className="flex flex-col items-center h-[calc(100svh-64px)] space-y-5">
            <div className="card w-96 bg-neutral shadow-xl">
                <div className="card-body bg-base-300">
                    <em><p>「如果你想要制造羁绊，就得承受流泪的风险。」</p>
                    <p>「也许世界上也有五千朵和你一模一样的花，但只有你是我独一无二的玫瑰。」</p>
                    <p>「因为你把时间投注在你的玫瑰花身上，所以，她才会如此重要。」</p>
                    <br />
                    <p>——《小王子》</p></em>
                </div>
                <div className="card-body items-center">
                    <p className="text-2xl font-bold text-center">从零开始定制你的AI伴侣</p>
                    <ol>
                        <li>🌟 可以定制角色的人设、形象</li>
                        <li>🌟 可以定制角色的生活经历</li>
                        <li>🌟 可以定制角色的话题偏好</li> 
                    </ol>
                    <button onClick={()=>showModal("premium-1-month")} className="btn btn-outline btn-secondary w-36">29.9元/月</button>
                    <button onClick={()=>showModal("premium-6-month")} className="btn btn-outline btn-secondary w-36">149.9元/半年</button>
                </div>
            </div>

            <dialog id={notYetModalID} className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">当前功能还在开发中</h3>
                    <p className="py-4">你可以<Link to={"/contact"}><u>联系我们</u></Link>来获得最新动态</p>
                    <div className="modal-action">
                    <form method="dialog" className="space-x-4">
                        <button className="btn" >好的</button>
                    </form>
                    </div>
                </div>
            </dialog>
        </div>
    )
}