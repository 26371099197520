import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchInfoAPI } from '../utils/api.ts';
import { User } from '../utils/type.ts';

interface UserContextType {
    user: User | null;
    setUser: (user: User | null) => void;
    fetchUserAsync: () => Promise<void>;
    loading: boolean;
}

// Define a default value for the context
const defaultUserContextValue: UserContextType = {
    user: null,
    setUser: () => {}, // No-op function for default
    fetchUserAsync: async () => {}, // Async no-op function
    loading: true,
};

const UserContext = createContext<UserContextType>(defaultUserContextValue);

export function useUser() {
    return useContext(UserContext);
}

export const UserProvider = ({ children }) => {

    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    const fetchUserAsync = async () => {
        setLoading(true)
        setUser(null)

        const data = await fetchInfoAPI();
        setUser(data)
        setLoading(false)
    }

    useEffect(() => {
        fetchUserAsync()
    }, [])

    return (
        <UserContext.Provider value={{ user, setUser, fetchUserAsync, loading }}>
            {children}
        </UserContext.Provider>
    );
};