import React, { useEffect, useState } from "react";
import NavbarContainer from "../NavbarContainer.tsx";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import Zoom from 'react-medium-image-zoom'
import LoadingPage from "../LoadingPage.tsx";
import Markdown from "../common/markdown.tsx";
import { useUser } from "../context/UserContext.tsx";

const DEFAULT_PLACEHOLDER = "在此输入评论..."


export default function BroadcastTopicPage() {

    const { topic_id } = useParams();
    
    const { user } = useUser()

    const [topic, setTopic] = useState({
        title: "",
        content: "",
        source_name: "",
        image_prompt: "",
        sample_image: "",
        time_created: "",
        comments: [],
    })

    const fetchTopic = async () => {
        const promptPattern = /::generate-image\[(.*?)\]\{(.*?)\}/;

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/topics/${topic_id}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json();
            const matches = data["content"].match(promptPattern);
            var content = data["content"]
            var image_prompt = ""
            if (matches) {
                image_prompt = matches[1].replace(/,best quality,masterpiece$/, '')
                content = content.replace(matches[0], '').trim();
            }
            console.log(data)
            setTopic({
                title: data["title"],
                content: content,
                source_name: data["source_name"],
                image_prompt: image_prompt,
                sample_image: data["sample_image"],
                time_created: data["time_created"],
                comments: data["comments"],
            })
        } catch (error) {
            toast.error("话题未公开")
            return
        }
    }

    useEffect(()=>{
        fetchTopic()
    }, [])

    if (!topic.title) {
        return <LoadingPage />
    }

    function CommentForm({ comment_id, placeholder, can_delete }) {

        const [isCommentModalOpen, setCommentModalOpen] = useState(false);
    
        const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    
        const [comment, setComment] = useState('');
    
        const handleSubmit = async (event) => {
            event.preventDefault();
    
            let url = `${process.env.REACT_APP_API_URL}/broadcast/topics/${topic_id}/comments/add`;
            if (comment_id > 0) {
                url += `?parent_comment_id=${comment_id}`;
            }
    
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({content: comment}),
            });
            const data = await res.json();
    
            if (res.ok) {
                toast.success("发布成功");
                fetchTopic();
            } else {
                toast.error("发布失败");
            }
    
            setCommentModalOpen(false);
            setComment('');
        };
    
        const handleDeletion = async (event) => {
            event.preventDefault();
            const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/topics/${topic_id}/comments/delete/${comment_id}`, {
                method: 'DELETE',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json();
    
            if (res.ok) {
                toast.success("删除成功");
                fetchTopic();
            } else {
                toast.error("删除失败");
            }
    
            setDeleteModalOpen(false);
        };
    
        return (
            <div>
                <dialog className="modal" open={isCommentModalOpen}>
                    <div className="modal-box flex flex-col space-y-2">
                        <textarea className="textarea textarea-bordered w-full max-w-md" maxLength={1000} placeholder={placeholder} value={comment} onChange={e => setComment(e.target.value)} />
                        <div className="text-left space-x-4">
                            <button className="btn btn-sm btn-outline btn-secondary gap-2" onClick={handleSubmit} disabled={!comment}>发布</button>
                        </div>
                    </div>
                    <form method="dialog" className="modal-backdrop" onClick={() => setCommentModalOpen(false)} />
                </dialog>
                <dialog className="modal" open={isDeleteModalOpen}>
                    <div className="modal-box flex flex-col space-y-4 justify-center items-center">
                        <p className="text-2xl">确认删除</p>
                        <button className="btn btn-sm btn-outline btn-secondary gap-2" onClick={handleDeletion}>删除</button>
                    </div>
                    <form method="dialog" className="modal-backdrop" onClick={() => setDeleteModalOpen(false)} />
                </dialog>
                <i className="fa fa-regular fa-comment"></i>
                <button className="inline-flex items-center p-2 text-sm font-medium text-center" onClick={() => setCommentModalOpen(true)}>
                    {placeholder == DEFAULT_PLACEHOLDER ? "评论" : "回复"}
                </button>
                {can_delete && <button className="inline-flex items-center p-2 text-sm font-medium text-center" onClick={() => setDeleteModalOpen(true)}>删除</button>}
            </div>
        );
    }

    const ReplyComment = ({ comment, parent_author_username }) => (
        <div className="flex flex-col space-y-1">
            <div className="flex flex-row space-x-1 truncate">
                <p className="text-secondary">{comment.author_username}</p>
                <p>回复</p>
                <p className="text-secondary">@ {parent_author_username}</p>
                <p className="text-gray-600">{comment.time_created.split('T')[0]}</p>
            </div>
            <p>{comment.content}</p>
            <CommentForm comment_id={comment.id} placeholder={parent_author_username} can_delete={user?.id == comment.user_id} />
            {comment.children && comment.children.map((reply_comment, index) => (
                <ReplyComment key={index} comment={reply_comment} parent_author_username={comment.author_username} />
            ))}
        </div>
    )


    return (
        <NavbarContainer title="">
            <div className="flex flex-col justify-center items-center">
                <div className="p-5 w-full md:w-2/5 flex flex-col space-y-4">
                    <div className="flex flex-col space-y-2">
                        <p className="text-2xl">{topic.title}</p>
                        <p>@ {topic.source_name}</p>
                        <p><i className="fa-regular fa-calendar"></i> {topic.time_created.split("T")[0]}</p>
                    </div>
                    {
                        topic.image_prompt &&
                        <div className="space-y-4">
                            <Zoom>
                                <img src={topic.sample_image} className="rounded-lg"/>
                            </Zoom>
                            <div className="rounded bg-primary text-sm p-2">
                                <i className="fa-regular fa-image"></i> {topic.image_prompt}
                            </div>
                        </div>
                    }
                    <Markdown>{topic.content}</Markdown>

                    <section className="antialiased">
                        <p className="text-2xl">评论</p>
                        <CommentForm comment_id={0} placeholder={DEFAULT_PLACEHOLDER} can_delete={false} />
                        <br/>
                        {topic.comments.map((comment:any, index) => (
                            <div>
                                <article className="text-base rounded-lg flex flex-col space-y-1">
                                    <div className="flex flex-row space-x-1 truncate">
                                        <p className="text-secondary">{comment.author_username}</p>
                                        <p className="text-gray-600">{comment.time_created.split('T')[0]}</p>
                                    </div>
                                    <p>{comment.content}</p>
                                    <CommentForm comment_id={comment.id} placeholder={`回复 @ ${comment.author_username}`} can_delete={user?.id == comment.user_id} />
                                </article>
                                <article className="mb-3 ml-6 lg:ml-12 text-base">
                                    {comment.children && comment.children.map((child_comment:any, index) => (
                                        <div className="flex flex-col space-y-1">
                                            <div className="flex flex-row space-x-1 truncate">
                                                <p className="text-secondary">{child_comment.author_username}</p>
                                                <p className="text-gray-600">{child_comment.time_created.split('T')[0]}</p>
                                            </div>
                                            <p>{child_comment.content}</p>
                                            <CommentForm comment_id={child_comment.id} placeholder={`回复 @ ${child_comment.author_username}`} can_delete={user?.id == child_comment.user_id} />
                                            {child_comment.children && child_comment.children.map((reply_comment, index) => (
                                                <ReplyComment comment={reply_comment} parent_author_username={child_comment.author_username} />
                                            ))}
                                        </div>
                                    ))}
                                </article>
                            </div>
                        ))}
                    </section>
                </div>
            </div>
        </NavbarContainer>
    );
}