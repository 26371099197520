import './ChatPage.css';
import { useState } from "react";
import toast from "react-hot-toast"
import Cookies from 'js-cookie';

export default function RatingEntry(props) {
    const { rating, eventId } = props;
    const [liked, setLiked] = useState(rating == 5)
    const [processing, setProcessing] = useState(false)

    const toggleliked = async () => {

        if (processing) {
            return
        }
        setProcessing(true)
        
        const newValue = liked ? 0: 5;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/rating/${eventId}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
                body: JSON.stringify({
                    rating: newValue,
                }),
            });

            const status = await response.status;

            if (response.ok) {
                if (!liked) {
                    toast.success("作者表示感谢🙏")
                }
                setLiked(!liked)
                setProcessing(false)
                return
            } else {
                toast.error("请稍后重试")
                setProcessing(false)
                return
            }
        } catch (error) {
            toast.error("请稍后重试")
            setProcessing(false)
            return
        }
    };

    return (
        
        <button className={`btn btn-white btn-sm`} onClick={toggleliked} disabled={processing}>
            <i className={`fa fa-thumbs-up ${liked && "text-red-400"}`} />
        </button>
    );
}