import React from 'react';
import './ChatPage.css';
import { useState, useEffect } from "react";
import { createBroadcastTopic, generateImage, getCharacterBroadcasts, uploadResource } from '../utils/api.ts';
import toast from 'react-hot-toast';
import { prettifyContent } from '../utils/content.ts';
import Zoom from 'react-medium-image-zoom';

const NEW_TOPIC_MODAL_ID = "new-topic-button-modal"

function AIWriting({character, onTopicSelected, setMode }) {

  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchTopics() {
    if (!character) {
      return
    }
    setLoading(true);
    
    const topics = await getCharacterBroadcasts(character.id)
    setTopics(topics)
    setLoading(false);
  }

  useEffect(() => {
    fetchTopics();
  }, []);

  return (
    <div className="flex flex-col space-y-4">
      {!loading && topics.length == 0 && <p>这个角色暂时没有新的话题了😫</p>}
      {loading ? (
        <div className="text-center">
          <div className="loading loading-spinner"></div>
        </div>
      ) : (
        <div className="flex flex-col space-y-2">
          {topics.map((topic, index) => (
            <div key={index} className="flex flex-col items-start px-3 py-1 bg-primary rounded" onClick={() => onTopicSelected(topic, false)}>
                <p className="truncate text-left pt-1">{topic["title"]}</p>
                <div className="flex flex-row">
                    <p className="text-left text-xs text-secondary mr-4 w-24">{`@${topic["author_username"]}`}</p>
                    <i className="fa fa-pencil text-secondary text-xs mr-1"></i>
                    <p className="text-left text-xs text-secondary">{topic["count"]}</p>
                </div>
                <div className="text-left text-sm text-secondary overflow-hidden text-ellipsis whitespace-nowrap w-full">{
                  prettifyContent(topic)
                }</div>
            </div>
          ))}
        </div>
      )}
      <div className="text-right space-x-4">
        <button onClick={()=>setMode(1)} className="btn btn-sm btn-outline btn-secondary gap-2">
          <i className="fa fa-pencil text-secondary"></i> 自己写
        </button>
        <button onClick={fetchTopics} className="btn btn-sm btn-outline btn-secondary gap-2">
          <i className="fa fa-dice text-secondary"></i> 换一换
        </button>
      </div>
    </div>
  )
}

function HumanWriting({ character, onTopicSelected, setMode }) {
  const [content, setContent] = useState("");
  const [enableImage, setEnableImage] = useState(true);
  const [imagePrompt, setImagePrompt] = useState("");
  const [enableShare, setEnableShare] = useState(false);
  const [title, setTitle] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false)

  const submit = async () => {
    if ( submitLoading ) {
      return
    }
    setSubmitLoading(true)
    // upload image if needed
    var imageURL = imageSrc
    if (imageSrc.startsWith("blob:")) {
        let blob = await (await fetch(imageSrc)).blob();
        let fileName = "image";
        let file = new File([blob], fileName, { type: blob.type });
        const serverSrc = await uploadResource(file, ()=> {})
        if (!serverSrc) {
          setSubmitLoading(false)
          return
        }
        imageURL = serverSrc
        setImageSrc(imageURL)
    }
    var tmp = content
    if (enableImage) {
      tmp = `![自拍](${imageURL})\n${content}`
    }
    const id = await createBroadcastTopic(0, title, tmp, enableShare, character.id)
    toast.success("话题创建成功")
    const success = await onTopicSelected({
      "id": id
    }, false)
  }

  const [imageSrc, setImageSrc] = useState("")
  const [imageLoading, setImageLoading] = useState(false)

  const handlePortraitImageUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
        const img = e.target.files[0];
        const url = URL.createObjectURL(img)
        console.log(url)
        setImageSrc(url);
    } else {
        URL.revokeObjectURL(imageSrc)
        setImageSrc("")
    }
}

  const generateImageWrappper = async() => {
    if ( imageLoading || !imagePrompt) {
      return
    }
    setImageSrc("")
    setImageLoading( true )
    const prompt = `${imagePrompt},best quality,masterpiece`
    const images = await generateImage(prompt, character.avatar_url);
    console.log(images)
    if (images.length > 0) {
      setImageSrc(images[0])
      setImageLoading(false)
    }
    setImageLoading(false)
  };

  const imageGenerationSection = () => (
    <div className="flex flex-col space-y-1">
      <div className="flex flex-row space-x-4">
        <input type="text" name="appearance" placeholder="一个男生在海边散步。" maxLength={100} value={imagePrompt}
          onChange={e=>setImagePrompt(e.target.value)} className="input input-bordered w-full max-w-md"
        />
        <button className="btn btn-primary" onClick={generateImageWrappper} disabled={imageLoading}>
          {imageLoading ? <span className="loading loading-spinner"></span> : null}生成
        </button>
      </div>
      <p>或者</p>
      <label htmlFor="portrait-upload" className="btn btn-primary">上传图片</label>
      <input id="portrait-upload" className="file-input" type="file" onChange={handlePortraitImageUpload} hidden />
      {imageSrc && <Zoom><img src={imageSrc} alt="portrait" className="h-48 rounded" /></Zoom>}
    </div>
  )

  const valid = () => {
    if (enableImage && imageSrc === "") return false
    if (enableShare && title === "") return false
    return content!=""
  }

  return (
    <div className="flex flex-col space-y-2">
      <textarea placeholder="TA要说的内容" maxLength={1000} value={content} onChange={e=>setContent(e.target.value)} className="textarea textarea-bordered w-full max-w-md"></textarea>
      <label className="flex p-1 space-x-2">
          <input type="checkbox" className="checkbox checkbox-sm checkbox-secondary" checked={enableImage} onChange={()=>setEnableImage(!enableImage)} />
          <span className="label-text text-secondary">想让TA自拍</span> 
      </label>
      {enableImage && imageGenerationSection()}

      <label className="flex p-1 space-x-2">
          <input type="checkbox" className="checkbox checkbox-sm checkbox-secondary" checked={enableShare} onChange={()=>setEnableShare(!enableShare)} />
          <span className="label-text text-secondary">分享给其他用户</span> 
      </label>
      {enableShare && 
        <div className='flex flex-col'>
          <input placeholder="为你的话题起个标题吧！" maxLength={100} value={title} onChange={e=>setTitle(e.target.value)} className="textarea textarea-bordered w-full max-w-md"></input>
        </div>
      }
      <div className="text-right space-x-4">
        <button onClick={() => submit()} disabled={!valid()} className="btn btn-sm btn-outline btn-secondary gap-2">
          <i className="fa fa-pencil text-secondary"></i> 提交
        </button>
        <button onClick={() => setMode(0)} className="btn btn-sm btn-outline btn-secondary gap-2">
          <i className="fa fa-circle-left text-secondary"></i> 帮我写
        </button>
      </div>
    </div>
  )
}

function TopicsDialog({character, onTopicSelected }) {
  
  const [mode, setMode] = useState(0)

  const onTopicSelectedWrapper = (topic, rewrite) => {
    onTopicSelected(topic, rewrite)
    setMode(0)
  }

  return (
    <dialog className="modal" id={NEW_TOPIC_MODAL_ID}>
      <div className="modal-box flex flex-col space-y-2">
        <h3 className="font-bold text-lg">想让TA聊点什么...</h3>
        {mode == 0 && <AIWriting character={character} onTopicSelected={onTopicSelectedWrapper} setMode={setMode} />}
        {mode == 1 && <HumanWriting character={character} onTopicSelected={onTopicSelectedWrapper} setMode={setMode}/>}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}

export default function NewTopicButton({character, cookAIText, processing}) {

  async function handleTopicSelected(topic: {id: string}, rewrite: boolean) {
    if (!character) {
      return
    }
    closeModal()
    await cookAIText(character.id, topic.id, rewrite)
  }

  function showModal() {
    const modal = document.getElementById(NEW_TOPIC_MODAL_ID) as HTMLDialogElement
    if (modal) modal.showModal()
  }

  function closeModal() {
    const modal = document.getElementById(NEW_TOPIC_MODAL_ID) as HTMLDialogElement
    if (modal) modal.close()
  }

  return (
    <div>
      <button className="btn rounded-full" onClick={showModal} disabled={processing}>
        <i className="fa fa-pencil"></i>
      </button>
      <TopicsDialog
        character={character}
        onTopicSelected={handleTopicSelected}
      />
    </div>
  );
}