import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./context/UserContext.tsx";
import CharacterBrowse from "./character/CharacterBrowse.tsx";

export default function HomePage() {

    const {user} = useUser()

    return (
        <div className="flex flex-col items-center h-full">
            <div className="flex flex-col w-full">
                <div className="flex flex-col space-y-5 p-4 py-8">
                    <Link to={`https://bwbbsvqza3t.feishu.cn/wiki/MwPmwiB8pizBoqkCMMPccUYtnhc`}>
                        <div className="bg-primary p-3 shadow-sm flex flex-row">
                            <i className="fa-brands fa-android"></i><div>安卓安装包已发布</div>
                        </div>
                    </Link>
                    <CharacterBrowse />
                </div>
            </div>
            <Link
                className="fixed bottom-20 right-4 mb-4 mr-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                to={`/character/create/0`}
            >
                +
            </Link>
        </div>
    )
}
