import { useState, useEffect, useRef } from "react";
import { toast } from "react-hot-toast"
import Cookies from 'js-cookie';
import {Howl} from 'howler';
import { agentChat, agentChatStream, cookCharacterBroadcast, deleteCharacterEvent, fetchCharacterAPI } from "../utils/api.ts";
import { useCharacter } from "../context/CharacterContext.tsx";
import { Character } from "../utils/type.ts";

function useVoiceBot(charID: string) {

  const historyLSID = `history-${charID}`
  const [history, setHistory] = useState<Array<Object> | null>(() => {
    const localHistory = localStorage.getItem(historyLSID);
    return localHistory !== null ? JSON.parse(localHistory) : null;
  });
  useEffect(() => {
    if (history?.constructor === Array) {
      localStorage.setItem(historyLSID, JSON.stringify(history));
    }
  }, [history]);

  const characterLSID = `character-${charID}`
  const [character, setCharacter] = useState<Character | null>(() => {
    const localChar = localStorage.getItem(characterLSID);
    return localChar !== null ? JSON.parse(localChar) : null;
  });
  useEffect(() => {
    localStorage.setItem(characterLSID, JSON.stringify(character));
  }, [character]);

  const [processing, setProcessing] = useState(false)

  const [voiceOn, setVoiceOn] = useState(() => {
    const savedVoiceOn = localStorage.getItem('voiceOn');
    return savedVoiceOn !== null ? JSON.parse(savedVoiceOn) : true;
  });

  const moodOn = false

  useEffect(() => {
    localStorage.setItem('voiceOn', JSON.stringify(voiceOn));
  }, [voiceOn]);

  // make stateRef always have the current count
  // your "fixed" callbacks can refer to this object whenever
  // they need the current value.  Note: the callbacks will not
  // be reactive - they will not re-run the instant state changes,
  // but they *will* see the current value whenever they do run
  const stateRef = useRef();
  stateRef["processing"] = processing;

  const initialized = useRef(false)
  const connectWS = () => {
    // make sure only called once in React Strict mode
    // https://taig.medium.com/prevent-react-from-triggering-useeffect-twice-307a475714d7
    if (initialized.current) {
      return
    }
    initialized.current = true
    const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}?token=${encodeURIComponent(Cookies.get('ringriseusertoken'))}`);
    ws.onopen = () => {
      console.log('WebSocket connected to the server');
    };
    ws.onmessage = (event) => {
      console.log('Message from server:', event.data);
      const data = JSON.parse(event.data)
      const message = {
        "id": data.id,
        "type": data.type,
        "role": data.role,
        "content": data.content,
        "date": new Date(data.date),
      }
      setHistory(prev => [...prev || [], message])
    };
    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      // toast.error("websocket error")
    };
    ws.onclose = (event) => {
      console.log('WebSocket closed:', event);
      // toast.error("websocket closed")
      if (event.wasClean) {
        console.log(`Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
      } else {
        // e.g. the server process killed or network down
        // event.code is usually 1006 in this case
        console.error(`Connection died unexpectedly, code=${event.code}`);
      }
    };
    return () => {
      ws.close();
    };
  }

  // Run once on initialization
  useEffect(() => {
    const initializeCharacter= async () => {
      const character = await fetchCharacterAPI(charID)
      setCharacter(character)
    }
    const initializeHistory = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${charID}/history`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
          },
        });

        const data = await res.json();
        if (data.constructor === Array) {
          setHistory(data)
        }
        connectWS()
      } catch (e) {
        console.error(e);
      }
    };
    initializeCharacter();
    initializeHistory();
  }, []);

  const sentenceStop = "。！？.!?\n";
  
  const getFirstSentence = (text) => {
    const sentenceRegex = new RegExp("[^" + sentenceStop + "]+[" + sentenceStop + "]?");
    const match = sentenceRegex.exec(text.split("\n")[0]);
    return match ? match[0].trim() : '';
  } 

  const playAudio = async (text) => {
    text = getFirstSentence(text)
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${charID}/tts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
        },
        body: JSON.stringify({
          text: text
        })
      });

      const audioBlob = await res.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      if (audioUrl) {
        var sound = new Howl({
          src: [audioUrl],
          format: ['mp3']
        });
        sound.play();
        URL.revokeObjectURL(audioUrl);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const userText = (text: string, model: number) => {
    if (processing) {
      return
    }

    if (text) {
      const newMessage = {
        "role": "user",
        "content": text,
        "date": Date.now(),
        "type": "chat",
      }
      setHistory(prev => [...prev || [], newMessage]);
      try {
        if (model == 2) {
          send_stream([newMessage], model)
        } else {
          send([newMessage], model)
        }
      } catch (e) {
        toast.error("暂时无法发送🙅")
        setProcessing(false)
        return
      }
    }
  }

  const getUpdateMessages= (messages: any[], newContent: string, eventID: number) => {
    // Copy the messages array to avoid direct state mutation
    const updatedMessages = [...messages];

    // Check if the last message exists and if its eventId is unset (assuming "unset" means either null, undefined, or an empty string)
    if (updatedMessages.length > 0 && !updatedMessages[updatedMessages.length - 1].id) {
      // Update the eventId of the last message
      updatedMessages[updatedMessages.length - 1].id = eventID;
    }

    // Append the new message with the current eventId
    updatedMessages.push({
      id: eventID,
      type: "chat",
      role: "assistant",
      content: newContent,
      date: Date.now(),
      mood: 0,
    });

    return updatedMessages
  }


  const send = async (messages: any[], model: number) => {
    console.log("start send")
    setProcessing(true)

    const result = await agentChat(messages, charID, model, moodOn, ()=>{})
    if (!result) {
      setProcessing(false)
      return
    }
    console.log(result)

    // refresh cookie expire date
    const token = Cookies.get('ringriseusertoken')
    Cookies.set('ringriseusertoken', token, { expires: 7 })

    const content = result.reply
    const eventId = result.event_id
    const moodDelta = result.mood_delta
    if (voiceOn) {
      await playAudio(content)
    }
    setHistory(prev => getUpdateMessages(prev || [], content, eventId));
    setProcessing(false)
  }

  const send_stream = async (messages: any[], model: number) => {
    console.log("start send")
    setProcessing(true)
    var reply = ""
    var printedReply = ""
    var audioPlayed = false
    await agentChatStream(messages, charID, model, async (chunk)=>{
      const pattern = new RegExp(`[${sentenceStop}]`)
      reply += chunk
      if (voiceOn && !audioPlayed && pattern.test(reply)) {
        audioPlayed = true
        playAudio(getFirstSentence(reply)) // playAudio non-blocking
      }
      for (let char of chunk) {
        if (printedReply === "") {
          setHistory(prev => {
            const newHistory = [...prev || [], {
              "type": "chat",
              "role": "assistant",
              "content": printedReply + char,
              "date": Date.now(),
              "mood": 0,
            }]
            printedReply += char
            return newHistory
        })
        } else {
          setHistory(prev => {
            const newHistory = [...prev || []]
            newHistory[newHistory.length - 1] = {
              "type": "chat",
              "role": "assistant",
              "content": printedReply + char,
              "date": Date.now(),
              "mood": 0,
            }
            printedReply += char
            return newHistory
          });
        }
        await new Promise(r => setTimeout(r, 30));
      }
    }, ()=>{})

    // refresh cookie expire date
    const token = Cookies.get('ringriseusertoken')
    Cookies.set('ringriseusertoken', token, { expires: 7 })
    setProcessing(false)
  }

  const clearHistory = async (charID, timeBy) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/characters/${charID}/history/bulkdelete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
        },
        body: JSON.stringify({
          "time_by": timeBy,
        })
      })
    } catch (e) {
      console.log(e)
      toast.error("Failed")
      return
    }
    setHistory([])
  }

  const cookAIText = async (character_id: string, topic_id: number, rewrite: boolean) => {
    setProcessing(true)
    toast("生成中，请稍等")
    const result = await cookCharacterBroadcast(character_id, topic_id, rewrite)
    if (result) {
      console.log(result)
      setHistory([...(history || []),
        {
          "id": result["event_id"],
          "type": "broadcast",
          "role": "assistant",
          "content": result["content"],
          "date": Date.now(),
          "mood": 0,
          "broadcast_id": result["broadcast_id"],
        }
      ]);
    } else {
      toast.error("生成失败，请稍后再试");
      setProcessing(false)
      return false
    }
    setProcessing(false)
    return true
  }

  const deleteEvent = async (character_id: string, event_id: number) => {
    if (!history) return

    const _ = await deleteCharacterEvent(character_id, event_id)
    setHistory(prev => (prev || []).filter(item => item["id"] !== event_id))
  }

  const regenEvent = async (character_id: string, event_id: number, model: number) => {
    if (!history) return

    const first = history.find(item => item["id"] == event_id)
    if (!first) return

    // normal case, delete and resend
    if (first["role"] == "user" && first["type"] == "chat") {
      const text = first["content"]
      const _ = await deleteEvent(character_id, event_id)
      userText(text, model)
    }

    // broadcast, recook
    if (first["type"] == "broadcast" && first["broadcast_id"]) {
      const broadcast_id = first["broadcast_id"]
      const _ = await deleteEvent(character_id, event_id)
      await cookAIText(character_id, broadcast_id, true)
    }
  }

  return {
    processing,
    character,
    history,
    userText,
    cookAIText,
    deleteEvent,
    regenEvent,
    voiceOn,
    setVoiceOn,
  };
}


export default useVoiceBot;
