export interface Character {
    id: string
    name: string
    author_user_id: number
    author_username: string
    gender: string
    personality: string
    avatar_url: string
    portrait_url: string
    appearance: string
    extra_prompt: string
    bio: string
    greeting: string
    public: boolean
    count: number
}

export const EmptyCharacter = {
    id: "",
    name: "",
    author_user_id: 0,
    author_username: "",
    gender: "",
    personality: "",
    avatar_url: "",
    portrait_url: "",
    appearance: "",
    extra_prompt: "",
    bio: "",
    greeting: "",
    public: false,
    count: 0,
};

export interface User {
    id: number
    username: string
    nickname: string
    group: string
}
