import React, { useEffect, useRef, useState } from 'react';
import { useCharacter } from '../context/CharacterContext.tsx';
import { Link, useNavigate } from 'react-router-dom';
import { Character } from '../utils/type.ts';
import Markdown from '../common/markdown.tsx';

const ImageWithSkeleton = ({ src, alt, ...props }) => {
    // State to keep track of whether the image has loaded
    const [loaded, setLoaded] = useState(false);
  
    return (
      <div className="w-full h-full">
        {/* Skeleton Loader */}
        {!loaded && <div className="skeleton w-full h-full"></div>}
        
        {/* Actual Image */}
        <img
          src={src}
          alt={alt}
          {...props}
          style={{ display: loaded ? 'block' : 'none' }}
          onLoad={() => setLoaded(true)}
          className={`${loaded ? '' : 'hidden'}`}
        />
      </div>
    );
};

function CharacterBrowse() {

    const {publicCharacters, fetchPublicCharacterAsync} = useCharacter()
    const navigate = useNavigate()
    const [selectedCharacter, setSelectedCharacter] = useState<null|Character>()

    useEffect(()=> {
        fetchPublicCharacterAsync()
    }, [])

    const characterPreviewDialogRef = useRef<null | HTMLDialogElement>(null)

    const bio = (character: Character)=> (
        <div className="p-4">
            <div className="card card-compact card-body bg-slate-200">
                <Markdown>{`**简介** ${character.bio}`}</Markdown>
            </div>
        </div>
    )

    const chatEntry = (character: Character)=> (
        <div className={`chat chat-start`}>
            <div className="chat-image avatar">
                <div className="w-12 rounded-full">
                    <img src={character.avatar_url} />
                </div>
            </div>
            <div className={`break-words max-w-full chat-bubble chat-bubble-primary`}>
                <Markdown>
                    {character.greeting}
                </Markdown>
            </div>
        </div>
    )

    const characterPreviewDialog = () => {
        if (selectedCharacter == null) {
            return null
        }
        return (
            <dialog ref={characterPreviewDialogRef} className="modal">
                <div className="modal-box space-y-2">
                    <h3 className="font-bold text-lg">角色预览</h3>
                    <div className="flex flex-col space-y-2 pb-2" >
                        {bio(selectedCharacter!)}
                        {chatEntry(selectedCharacter!)}
                    </div>
                    <div className="modal-action">
                        <form method="dialog" className="space-x-4">
                            <button className="btn btn-outline" onClick={()=>navigate(`/c/${selectedCharacter?.id}`)}>开始聊天</button>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }
    function showConfirmCreationDialog(character) {
        setSelectedCharacter(character)
        if (characterPreviewDialogRef && characterPreviewDialogRef.current) characterPreviewDialogRef.current.showModal()
    }

    return (
        <div className="w-full">
            {characterPreviewDialog()}
            <div className="flex flex-wrap">
                {publicCharacters.map((character, index) => (
                    <div key={index} className="p-1 w-1/2 md:w-1/3 lg:w-1/5">
                        <div onClick={()=>showConfirmCreationDialog(character)} className="card card-compact bg-base-100">
                            <figure className="avatar">
                                <ImageWithSkeleton src={character.portrait_url} alt={`Avatar of ${character.name}`} />
                                {/* Badge for number of messages */}
                                <div className="absolute bottom-2 left-2 text-white text-xs w-full h-4">
                                    <i className="fa fa-comment-dots"></i> {character.count}
                                </div>
                            </figure>
                            <div className="card-body">
                                <div className="text-xs line-clamp-1">
                                    <b>{character.name}</b>
                                </div>
                                <div className="text-xs text-slate-400">
                                    @{character.author_username}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CharacterBrowse;