import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchCharacterAPI } from '../utils/api.ts';
import { Character } from '../utils/type.ts';
import { useUser } from './UserContext.tsx';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';

interface CharacterContextType {
    publicCharacters: Character[]
    fetchPublicCharacterAsync: () => Promise<void>;
    linkedCharacters: Character[]
    fetchLinkedCharacterAsync: () => Promise<void>;
    loading: boolean;
}

// Define a default value for the context
const defaultCharacterContextValue: CharacterContextType = {
    publicCharacters: [],
    linkedCharacters: [],
    fetchPublicCharacterAsync: async ()=>{},
    fetchLinkedCharacterAsync: async ()=>{},
    loading: true,
};
  
const CharacterContext = createContext<CharacterContextType>(defaultCharacterContextValue);

export function useCharacter() {
    return useContext(CharacterContext);
}

export const CharacterProvider = ({ children }) => {

    const [linkedCharacters, setLinkedCharacter] = useState<Character[]>([])
    const [myCharacters, setMyCharacters] = useState<Character[]>([])
    const [publicCharacters, setPublicCharacters] = useState<Character[]>([])
    const [loading, setLoading] = useState(true);
    const {user} = useUser()

    const fetchPublicCharacterAsync = async () => {
        setLoading(true)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/list`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
              },
              body: JSON.stringify({
                "sorting": "combined",
              })
            });
        
            if (res.status !== 200) {
              toast.error("获取角色失败🙅");
              setLoading(false)
              return
            }
        
            const data = await res.json();
            setPublicCharacters(
                data["characters"]
            )
            console.log(data["characters"])
            setLoading(false)
            return
        } catch (e) {
            toast.error("获取角色失败🙅");
            setLoading(false)
            return
        }
    }

    const fetchLinkedCharacterAsync = async () => {
        if (!user) {
            return 
        }
        setLoading(true)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/authedlist`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
              },
              body: JSON.stringify({
                "chatted_with_user_id": user.id,
              })
            });
        
            if (res.status !== 200) {
              toast.error("获取角色失败🙅");
              setLoading(false)
              return
            }
        
            const data = await res.json();
            setLinkedCharacter(
                data["characters"]
            )
            console.log(data["characters"])
            setLoading(false)
            return
        } catch (e) {
            toast.error("获取角色失败🙅");
            setLoading(false)
            return
        }
    }

    return (
        <CharacterContext.Provider value={{ loading, publicCharacters, fetchPublicCharacterAsync, linkedCharacters, fetchLinkedCharacterAsync }}>
            {children}
        </CharacterContext.Provider>
    );
};