import { useEffect, useState } from "react";
import { writerListCharacterAPI } from "../utils/api.ts";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import NavbarContainer from "../NavbarContainer.tsx";

function WriterCharactersPage() {

  const [characters, setCharacters] = useState<{ starve: boolean, id: string, name: string, gender: string }[]>([]);
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      const chars = await writerListCharacterAPI(() => { navigate("/login") })
      console.log(chars)
      setCharacters(chars)
    })()
  }, []);

  return (
    <NavbarContainer title="角色列表">
      <ul className="menu bg-base-100 w-56 p-2 rounded-box">
        {characters.map((char, index) => (
          <Link key={index} to={`/writing/characters/${char.id}`}>
            <li key={char.id} className={char.starve ? 'bg-red-500' : 'bg-green-500'}>
              <a>
                <span>{char.name}</span><br />
                <span>{char.gender}</span><br />
                <span>{char.starve ? 'Starving' : 'Fed'}</span>
              </a>
            </li>
          </Link>
        ))}
      </ul>
    </NavbarContainer>
  );
}

export default WriterCharactersPage;