import React, { useState } from "react";
import { toast } from "react-hot-toast"
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { useUser } from "./context/UserContext.tsx";


export default function LoginPage() {
    const { fetchUserAsync } = useUser()

    const navigate = useNavigate()
    const [username, setUsername] = useState("");
    const [nickname, setNickname] = useState("");
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [processing, setProcessing] = useState(false)
    const [usernameValidationFailed, setUsernameValidationFailed] = useState(false);
    const [nicknameValidationFailed, setNicknameValidationFailed] = useState(false);
    const [passwordValidationFailed, setPasswordValidationFailed] = useState(false);
    const [passwordConfirmationValidationFailed, setPasswordConfirmationValidationFailed] = useState(false);

    function validateUsername(username: string): boolean {
        const regex = /^[a-zA-Z0-9]{8,16}$/;
        return regex.test(username);
    }

    function validatePassword(password: string): boolean {
        const hasValidLength = /^[a-zA-Z0-9]{8,16}$/.test(password);
        const hasBothEnglishAndNumber = /[a-zA-Z]/.test(password) && /[0-9]/.test(password);
        return hasValidLength && hasBothEnglishAndNumber;
    }

    function validateNickname(nickname: string): boolean {
        const hasValidLength = /^[\u4e00-\u9fa5a-zA-Z0-9]{2,16}$/.test(nickname);
        return hasValidLength;
    }

    const handleUsernameChange = (e) => {
        const username = e.target.value;
        setUsername(username);
        const validated = validateUsername(username);
        setUsernameValidationFailed(!validated);
    };

    const handleNicknameChange = (e) => {
        const nickname = e.target.value;
        setNickname(nickname);
        const validated = validateNickname(nickname);
        setNicknameValidationFailed(!validated);
    };

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        setPassword(password);
        const validated = validatePassword(password);
        setPasswordValidationFailed(!validated);
    };

    const handleconfirmedPasswordChange = (e) => {
        const passwordConfirm = e.target.value;
        setConfirmedPassword(passwordConfirm);
        setPasswordConfirmationValidationFailed(passwordConfirm !== password)
    };

    const handRegisterRequest = async () => {
        console.log("Handle register request")
        console.log(`Username: ${username}`)
        console.log(`Nickname: ${nickname}`)
        console.log(`Password: ********************`)

        if (confirmedPassword !== password) {
            console.log("Password not matched!")
            toast.error("两次输入的密码不相同🙅")
            return
        }
        setProcessing(true)
        var res;
        try {
            res = await fetch(`${process.env.REACT_APP_API_URL}/user/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "username": username,
                    "nickname": nickname,
                    "password": password
                }),
            })
        } catch (e) {
            toast.error("注册失败了🙅")
            setProcessing(false)
            return
        }

        const status = await res.status;
        if (status !== 200) {

            try {
                const data = await res.json();
                const detail = data.detail;
                if (detail == "Username already registered") {
                    toast.error("用户名已存在，请使用其他用户名🙅")
                    setProcessing(false)
                    return
                }
                if (detail == "Invalid user name") {
                    toast.error("用户名不合法，长度为8至16位，只可包含字母或数字🙅")
                    setProcessing(false)
                    return
                }
                if (detail == "Invalid nickname") {
                    toast.error("昵称不合法，长度为2至16位，只能包含中文、英文和数字🙅")
                    setProcessing(false)
                    return
                }
                if (detail == "Invalid password") {
                    toast.error("密码不合法，长度为8至16位，必须同时包含英文字母和数字🙅")
                    setProcessing(false)
                    return
                }
            } catch (e) {
                console.log("Failed to handle the response body for error")
            }
            toast.error("注册失败了🙅")
            setProcessing(false)
            return
        }

        const data = await res.json();
        const access_token = data.access_token;
        Cookies.set('ringriseusertoken', access_token, { expires: 7 })
        await fetchUserAsync()
        setProcessing(false)
        navigate("/")
    };

    const handleRedirectToLogin = async () => {
        setProcessing(false)
        navigate("/login")
    };


    return (
        <div className="flex flex-col h-screen justify-center items-center">
            <div className="w-full p-6 m-auto bg-white rounded-md lg:max-w-lg">
                <div className="flex flex-row" >
                    <img src="/logo192.png" alt="logo" className="w-20 h-20" />
                    <h1 className="text-3xl font-semibold text-center p-8">初次见面</h1>
                </div>
                <div className="space-y-4">
                    <div>
                        <label className="label">
                            <span className="text-base label-text">昵称</span>
                        </label>
                        <input type="text" placeholder="角色会用昵称来称呼你" className="w-full input input-bordered input-primary" onChange={handleNicknameChange} />
                        <label className="label" style={{ display: nicknameValidationFailed ? 'block' : 'none' }}>
                            <span className="text-base label-text" style={{ color: 'red' }}>昵称长度为2至16位，只能包含中文、英文和数字</span>
                        </label>
                    </div>
                    <div>
                        <label className="label">
                            <span className="text-base label-text">用户名</span>
                        </label>
                        <input type="text" placeholder="长度为8至16位，只能包含字母或数字" className="w-full input input-bordered input-primary" onChange={handleUsernameChange} />
                        <label className="label" style={{ display: usernameValidationFailed ? 'block' : 'none' }}>
                            <span className="text-base label-text" style={{ color: 'red' }}>用户名长度为8至16位，只可包含字母或数字</span>
                        </label>
                    </div>
                    <div>
                        <label className="label">
                            <span className="text-base label-text">密码</span>
                        </label>
                        <input type="password" placeholder="长度为8至16位，必须同时包含英文字母和数字"
                            className="w-full input input-bordered input-primary" onChange={handlePasswordChange} />
                        <label className="label" style={{ display: passwordValidationFailed ? 'block' : 'none' }}>
                            <span className="text-base label-text" style={{ color: 'red' }}>密码长度为8至16位，必须同时包含英文字母和数字</span>
                        </label>
                    </div>
                    <div>
                        <label className="label">
                            <span className="text-base label-text">确认密码</span>
                        </label>
                        <input type="password" placeholder="再次输入相同的密码"
                            className="w-full input input-bordered input-primary" onChange={handleconfirmedPasswordChange} />
                        <label className="label" style={{ display: passwordConfirmationValidationFailed ? 'block' : 'none' }}>
                            <span className="text-base label-text" style={{ color: 'red' }}>两次输入的密码必须相同</span>
                        </label>
                    </div>
                    <div className="flex flex-col">
                        <button className="btn btn-primary" onClick={handRegisterRequest}
                            disabled={processing || usernameValidationFailed || nicknameValidationFailed || passwordValidationFailed || passwordConfirmationValidationFailed}>注册</button>
                    </div>
                    <hr className="solid p-y-10"></hr>
                    <div className="flex flex-col">
                        <button className="btn btn-accent" onClick={handleRedirectToLogin} disabled={processing}>返回登录</button>
                    </div>
                </div>
            </div>
        </div>
    )
}